import React, { ReactElement, useState } from 'react';
import "./ShoppingList.css";
import { CooklangNumber, deleteCategories, QuantityValue, saveCategories, SaveCategory } from '../data';
import { DragDropContext, Droppable, Draggable, OnDragEndResponder } from "@hello-pangea/dnd";
import { IngredientSource, ShoppingList as ShoppingListType, ShoppingListCategory, ShoppingListIngredient, sumSources, useShoppingList, useShoppingListDispatch, ShoppingAisleWithIngredients } from '../contexts/ShoppingListContext';
import { capitalizeFirst } from '../textFormattings';
import { getShortWeekDay, isSameDay } from '../dates';
import TickTickDialog from './TickTickDialog';
import { buildShoppingListString, displayQuantityValue } from '../utils/shoppingLists';
import EditableText from './EditableText';
import { reorderSortOrder } from '../utils/list';
import { DialogContentText, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from './ConfirmDialog';

export type IngredientInfoBuilder = (ingredient: ShoppingListIngredient) => ReactElement;

const categoryType = "category";
const ingredientType = "ingredient";
const shoppingListDroppableId = "shoppingList";

interface IngredientProps {
    ingredient: ShoppingListIngredient;
    index: number;
    onRemove: (ingredient: ShoppingListIngredient) => void;
    onChangeValue: (ingredient: ShoppingListIngredient, value: string) => void;
    buildIngredientInfo?: IngredientInfoBuilder;
}

function Ingredient({ ingredient, index, onRemove, onChangeValue, buildIngredientInfo }: IngredientProps) {
    return <Draggable key={ingredient.uniqueId} draggableId={ingredient.uniqueId} index={index} >
        {(provided, snapshot) => (
            <div
                className='shopping-list-ingredient'
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                <div>
                    <input
                        className='shopping-list-ingredient-input'
                        type='text'
                        value={ingredient.quantity ? displayQuantityValue(ingredient.quantity) : ""}
                        onChange={(e) => onChangeValue(ingredient, e.target.value)}></input>
                    {ingredient.unit}
                </div>
                <div className='name'>
                    <div>{capitalizeFirst(ingredient.name)}</div>
                    <div>{buildIngredientInfo?.(ingredient) ?? <></>}</div>
                </div>
                <div className='remove'>
                    <button onClick={() => onRemove(ingredient)}>Remove</button>
                </div>
            </div>
        )}
    </Draggable>;
}

interface ShoppingCategoryProps {
    index: number;
    category: ShoppingListCategory;
    aisle?: ShoppingAisleWithIngredients;
    buildIngredientInfo?: IngredientInfoBuilder;
}
function ShoppingCategory({ index, category, aisle, buildIngredientInfo }: ShoppingCategoryProps) {
    const dispatch = useShoppingListDispatch();
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

    const getListStyle = (isDraggingOver: boolean) => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
    });

    const onRename = async (newName: string) => {
        if (!category.name || !aisle || aisle.name !== category.name) return;

        const [newId] = await saveCategories([{ id: aisle.id, name: newName, sort_order: aisle.sort_order }]);

        dispatch({ type: 'rename-category', category: category.uniqueId, newName, newId });
    };

    const onCancelRename = () => {
        if (typeof category.id === "number") return;

        dispatch({ type: 'remove-category', category: category.uniqueId });
    };

    const onRemove = () => {
        setRemoveDialogOpen(true);
    };

    const onRemoveConfirm = async () => {
        if (aisle?.id) {
            await deleteCategories([{ id: aisle.id }]);
        }

        dispatch({ type: 'remove-category', category: category.uniqueId });
    };

    const categoryHeader = category.name ?
        <EditableText
            key={category.id || ""}
            value={category.name}
            onCommit={onRename}
            onCancel={onCancelRename}
            initialIsEditing={!category.id}
        >
            <IconButton onClick={onRemove} sx={{ marginLeft: '30px' }}>
                <DeleteIcon/>
            </IconButton>
        </EditableText> :
        <>Ingen kategori</>;

    return <Draggable
        key={category.uniqueId}
        draggableId={category.uniqueId}
        index={index}
        isDragDisabled={!category.name}
    >
        {(provided, snapshot) => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className='shopping-list-category'
            >
                <div className='shopping-list-category-header'>{categoryHeader}</div>
                <Droppable droppableId={category.uniqueId} type={ingredientType}>
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className='shopping-list-category-droppable'
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {category.items.map((i, index) => {
                                const onRemove = () => {
                                    dispatch({ type: 'remove', category: category.uniqueId, index });
                                };
                                const onChangeValue = (_ingredient: ShoppingListIngredient, value: string) => {
                                    dispatch({ type: 'change-value', category: category.uniqueId, index, value });
                                };
                                return <Ingredient
                                    key={i.uniqueId}
                                    ingredient={i}
                                    index={index}
                                    onRemove={onRemove}
                                    onChangeValue={onChangeValue}
                                    buildIngredientInfo={buildIngredientInfo}
                                ></Ingredient>;
                            })}
                            {provided.placeholder ? <div style={{ gridColumn: "span 3" }}>{provided.placeholder}</div> : <></>}
                            <ConfirmDialog
                                open={removeDialogOpen}
                                title={`Ta bort '${category.name}'`}
                                confirmText='Ta bort'
                                onConfirm={onRemoveConfirm}
                                onCancel={() => setRemoveDialogOpen(false)}
                            >
                                <DialogContentText>
                                    Om du tar bort kategorin så kommer alla ingredienser i {category.name} sorteras som
                                    okategoriserat. Vill du fortsätta?
                                </DialogContentText>
                            </ConfirmDialog>
                        </div>
                    )}
                </Droppable>
            </div>
        )}
    </Draggable>;
}

export interface ShoppingListProps {
    buildIngredientInfo?: IngredientInfoBuilder;
}

function ShoppingList({ buildIngredientInfo }: ShoppingListProps) {
    const { shoppingList: current, aisles } = useShoppingList();
    const newCategoryDisabled = current.some(l => !l.id && l.name);
    const dispatch = useShoppingListDispatch();
    const [tickTickOpen, setTickTickOpen] = useState(false);

    const onCopy = () => {
        const text = buildShoppingListString(current);
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text);
        }
        else {
            console.log(text);
            alert(text);
        }
    };

    const onTickTick = () => {
        setTickTickOpen(true);
    };

    const onNewCategory = () => {
        if (newCategoryDisabled) return;
        dispatch({ type: 'add-category', name: "Ny kategori", sortOrder: aisles[aisles.length - 1].sort_order + 100 });
    };

    const onDragEnd: OnDragEndResponder = (result) => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }

        const sInd = source.droppableId;
        const dInd = destination.droppableId;
        if (destination.droppableId === shoppingListDroppableId) {
            // We assume the 'no-category'-category is last.
            if (destination.index === (current.length - 1)) return;

            const aislesToUpdate = reorderSortOrder(aisles, source.index, destination.index).reduce((sum, res) => {
                const aisle = aisles[res.index];
                if (aisle.id) {
                    sum.push({ id:  aisle.id, name: aisle.name, sort_order: res.sort_order });
                }
                return sum;
            }, [] as SaveCategory[]);
            saveCategories(aislesToUpdate);
            
            dispatch({
                type: 'move-category',
                fromIndex: source.index,
                toIndex: destination.index
            });
        }
        else {
            dispatch({
                type: 'move',
                fromCategory: sInd,
                toCategory: dInd,
                fromIndex: source.index,
                toIndex: destination.index
            });
        }
    };

    return (
        <div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={shoppingListDroppableId} type={categoryType}>
                    {(provided, snapshot) => (
                        <div 
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className='shopping-list-wrapper'
                        >
                            {current.map((c, index) => {
                                return (<ShoppingCategory
                                    key={c.uniqueId}
                                    index={index}
                                    category={c}
                                    aisle={aisles[index]}
                                    buildIngredientInfo={buildIngredientInfo}
                                ></ShoppingCategory>);
                            })}
                            {provided.placeholder ? <div style={{ gridColumn: "span 3" }}>{provided.placeholder}</div> : <></>}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div>
                <button onClick={onCopy}>Copy</button>
                <button onClick={onTickTick}>TickTick</button>
                <button onClick={onNewCategory} disabled={newCategoryDisabled}>Ny kategori</button>
            </div>
            <TickTickDialog open={tickTickOpen} onClose={() => setTickTickOpen(false)} shoppingListToAdd={current}></TickTickDialog>
        </div>
    );
}

export default ShoppingList;