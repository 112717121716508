import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { PlannedMeal, dateToString, plannedMeals as getPlannedMeals } from '../data';
import { addDay, getWeekDay, isSameDay } from '../dates';
import { AuthStatus, useAuthContext } from '../contexts/AuthProvider';
import RecipeCard from '../components/RecipeCard';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { flushSync } from 'react-dom';
import PendingButton from '../components/PendingButton';

interface Day {
    date: Date;
    recipes: PlannedMeal[];
}

function getDateName(date: Date): string {
    const today = new Date();
    if (isSameDay(date, addDay(today, -1))) return "Igår";
    if (isSameDay(date, today)) return "Idag";
    if (isSameDay(date, addDay(today, 1))) return "Imorgon";
    if (isSameDay(date, addDay(today, 2))) return "Övermorgon";

    return dateToString(date)!;
}

function DayRecipe({ meal }: { meal: PlannedMeal }) {
    const recipe = meal.recipe;
    const link = `/recipe/${recipe.id}${meal.servings ? "?servings=" + meal.servings : ""}`;

    return <RecipeCard recipe={recipe} link={link}></RecipeCard>;
}

function Day({ day, highlight }: { day: Day, highlight: boolean; }) {
    const recipes = day.recipes.map((r, index) => <DayRecipe key={index} meal={r}></DayRecipe>);

    const weekDay = getWeekDay(day.date);
    const dateName = getDateName(day.date);

    return <Stack
        position='relative'
        direction={{ md: 'row' }}
        spacing={{ xs: '15px' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        zIndex='0'
    >
        <Stack
            width='150px'
            minWidth='150px'
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-end'
            sx={{ display: { xs: 'none', md: 'flex' }  }}
            useFlexGap
        >
            <Typography variant='h6'>{dateName}</Typography>
            <Typography variant='overline'>{weekDay}</Typography>
        </Stack>
        <Divider orientation='vertical' flexItem sx={{ display: { xs: 'none', md: 'block' } }}></Divider>
        <Divider
            orientation='horizontal'
            flexItem
            textAlign='left'
            sx={{ display: { md: 'none' }, padding: '0 15px' }}
        >
            <Typography>
                {dateName}
            </Typography>
        </Divider>
        <Stack
            direction='row'
            spacing={{ xs: '15px' }}
            useFlexGap
            sx={{
                paddingLeft: '15px',
                justifyContent: 'flex-start'
            }}
            flexGrow='1'
            flexWrap='wrap'
        >
            {recipes.length ? recipes : <div className='home-day-recipe unplanned'>Inget planerat</div>}
        </Stack>
        {!highlight ?
            <></> :
            <Box
                sx={{
                    position: 'absolute',
                    backgroundColor: '#9bdaff',
                    left: '50%',
                    transform: 'translate(-50%, 0%)',
                    height: '110%',
                    width: '100vw',
                    minWidth: '100vw',
                    margin: '0 !important',
                    zIndex: -1
                }}
            ></Box>}
    </Stack>;
}

function LoadMoreButton({ pending, onClick }: { pending?: boolean, onClick?: () => void; }) {
    return <PendingButton pending={pending} onClick={onClick}>Ladda mer</PendingButton>;
}

async function loadPlannedMeals(householdId: number | undefined, daysToShow: Date[]): Promise<Day[]> {
    const plannedMeals = await getPlannedMeals(householdId, daysToShow[0], daysToShow[daysToShow.length - 1]);
    const days = new Map<string, Day>();
    for (const meal of plannedMeals) {
        let entry = days.get(meal.date);
        if (!entry) {
            days.set(meal.date, entry = { date: new Date(meal.date), recipes: [] });
        }
        entry.recipes.push(meal);
    }

    // Add remaining days
    for (const day of daysToShow) {
        const key = dateToString(day)!;
        if (!days.has(key)) {
            days.set(key, { date: day, recipes: [] });
        }
    }

    const values = Array.from(days.values()).sort((a, b) => a.date.getTime() - b.date.getTime())
    return values;
}

function App() {
    const listRef = useRef<HTMLDivElement>(null);
    const todayRef = useRef<HTMLDivElement>(null);
    const auth = useAuthContext();
    const currentHouseholdId = auth.status === AuthStatus.SignedIn ? auth.currentHousehold : undefined;
    const today = new Date();

    const [plannedMeals, setPlannedMeals] = useState<Day[] | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const daysToShow = new Array(15).fill(0).map((_, index) => addDay(today, index - 5));
        loadPlannedMeals(currentHouseholdId, daysToShow).then(plannedMeals => {
            flushSync(() => setPlannedMeals(plannedMeals));
            todayRef.current?.scrollIntoView({ block: "center" });
        });
    }, [currentHouseholdId]);

    const onLoadMore = async (addBefore: boolean, getMoreDates: (currentMeals: Day[]) => Date[]) => {
        if (isLoading || !plannedMeals) return;

        setIsLoading(true);
        try {
            const moreDates = getMoreDates(plannedMeals);
            const morePlannedMeals = await loadPlannedMeals(currentHouseholdId, moreDates);
            if (addBefore) {
                const oldHeight = listRef.current?.scrollHeight;
                flushSync(() => setPlannedMeals(morePlannedMeals.concat(plannedMeals)));
                if (oldHeight !== undefined && listRef.current) {
                    const diff = listRef.current.scrollHeight - oldHeight;
                    listRef.current.scrollTop += diff;
                }
            }
            else {
                setPlannedMeals(plannedMeals.concat(morePlannedMeals));
            }
        }
        finally {
            setIsLoading(false);
        }
    };

    const onLoadEarlier = () => {
        onLoadMore(true, plannedMeals => {
            const startDate = plannedMeals[0].date;
            return new Array(15).fill(0).map((_, index) => addDay(startDate, -1 - index)).reverse();
        });
    };

    const onLoadLater = async () => {
        onLoadMore(false, plannedMeals => {
            const startDate = plannedMeals[plannedMeals.length - 1].date;
            return new Array(15).fill(0).map((_, index) => addDay(startDate, index + 1));
        });
    };

    return <Box
        sx={{
            backgroundColor: 'rgb(223, 239, 255)',
            pb: { md: '15px' },
            minHeight: "100%",
            flexGrow: '1',
            position: 'relative'
        }}
    >
        <Box
            sx={{
                position: 'absolute',
                height: '100%',
                left: 0,
                right: 0,
                overflowY: 'scroll',
                width: '100%'
            }}
            ref={listRef}
        >
            <Stack
                sx={{
                    maxWidth: { md: '1000px' },
                    ml: 'auto',
                    mr: 'auto',
                }}
                spacing='30px'
            >
                <LoadMoreButton pending={isLoading} onClick={onLoadEarlier}></LoadMoreButton>
                {plannedMeals ? plannedMeals.map(meal => {
                        const isToday = isSameDay(today, meal.date);
                        return <div key={meal.date.toISOString()} ref={isToday ? todayRef : null}><Day day={meal} highlight={isToday}></Day></div>;
                    }) : <span>Loading...</span>}
                <LoadMoreButton pending={isLoading} onClick={onLoadLater}></LoadMoreButton>
            </Stack>
        </Box>
    </Box>;
}

export default App;
