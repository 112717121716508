import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import SelectPortions from "./SelectPortions";
import { useEffect, useState } from "react";
import { addDay, getMonth, getWeekDay, normalize } from "../dates";
import PendingButton from "./PendingButton";
import { planMeals, plannedMeals } from "../data";
import { AuthStatus, useAuthContext } from "../contexts/AuthProvider";

export interface PlanMealDialogProps {
    open: boolean
    onClose?: () => void;
    recipeId: number;
    recipeName: string;
    servings?: number;
}

export default function PlanMealDialog({ open, onClose, recipeId, recipeName, servings: defaultServings }: PlanMealDialogProps) {
    const auth = useAuthContext();
    const currentHouseholdId = auth.status === AuthStatus.SignedIn ? auth.currentHousehold : undefined;
    const [days, setDays] = useState<Date[]>([]);
    const [selectedDay, setSelectedDay] = useState<Date | undefined>();
    const [servings, setServings] = useState<number>(defaultServings ?? 1);
    const [isPlanning, setIsPlanning] = useState(false);

    useEffect(() => {
        if (!open) return;

        const today = normalize(new Date());
        const daysToShow = new Array(14).fill(0).map((_, index) => addDay(today, index));
        setDays(daysToShow);
    }, [open, setDays]);

    useEffect(() => {
        setServings(defaultServings ?? 1);
    }, [defaultServings]);

    const onSelectDay = (event: SelectChangeEvent<number>) => {
        setSelectedDay(new Date(event.target.value as number));
    };
    
    const onPlan = async () => {
        if (currentHouseholdId === undefined || !selectedDay) return;

        setIsPlanning(true);
        try {
            const planned = await plannedMeals(currentHouseholdId, selectedDay, selectedDay);
            const existing = planned.map(p => ({ recipe_id: p.recipe.id, servings: p.servings ?? p.recipe.servings ?? 1}));
            await planMeals(
                currentHouseholdId,
                [
                    {
                        date: selectedDay,
                        planned: [ { recipe_id: recipeId, servings } ].concat(existing)
                    }
                ]
            );
            onClose?.();
        }
        finally {
            setIsPlanning(false);
        }
    };

    const onSelectPortions = (scaling: number) => {
        setServings(scaling);
    };

    return <Dialog
        open={open}
        onClose={onClose}
        fullWidth={true}
    >
        <DialogTitle>Planera {recipeName}</DialogTitle>
        <DialogContent>
            <Stack direction={{ xs: 'column', sm: 'row' }} padding='15px'>
                <FormControl sx={{ flexGrow: 1 }}>
                    <InputLabel id="plan-meal-select-day-label">Dag</InputLabel>
                    <Select
                        labelId="plan-meal-select-day-label"
                        label="Dag"
                        value={selectedDay?.getTime() ?? ""}
                        onChange={onSelectDay}
                    >
                        {days.map(day => {
                            const time = day.getTime();
                            return <MenuItem key={time} value={time}>
                                <Stack direction='row' justifyContent='space-between' width='100%'>
                                    <span>{getWeekDay(day)}</span>
                                    <span><i>{day.getDate()} {getMonth(day)}</i></span>
                                </Stack>
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>
                <SelectPortions scaling={servings} onSelectPortion={onSelectPortions}></SelectPortions>
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Avbryt</Button>
            <PendingButton onClick={onPlan} disabled={!selectedDay} pending={isPlanning} variant="contained">Planera</PendingButton>
        </DialogActions>
    </Dialog>;
}
