import React, { useEffect, useState } from 'react';
// import logo from './logo.svg';
import './Recipes.css';
import RecipesView from '../components/RecipesView';
import { loadRecipes, Recipe } from '../data';
import { AuthStatus, useAuthContext } from '../contexts/AuthProvider';
import { Helmet } from 'react-helmet';

function Recipes() {
    const auth = useAuthContext();
    const currentHouseholdId = auth.status === AuthStatus.SignedIn ? auth.currentHousehold : undefined;
    let [recipes, setRecipes] = useState<Recipe[] | null>(null);

    useEffect(() => {
        loadRecipes(currentHouseholdId).then(recipes => {
            setRecipes(recipes);
        });
    }, [currentHouseholdId]);

    return (
        <div className="Recipes">
            <Helmet>
                <title>Recept</title>
            </Helmet>
            <div className='recipesview-wrapper'>
                <RecipesView recipes={recipes}></RecipesView>
            </div>
        </div>
    );
}

export default Recipes;
