const weekDay = [
    "Söndag",
    "Måndag",
    "Tisdag",
    "Onsdag",
    "Torsdag",
    "Fredag",
    "Lördag",
];

const shortWeekDay = [
    "Sö",
    "Må",
    "Ti",
    "On",
    "To",
    "Fr",
    "Lö",
];

const months = [
    "Januari",
    "Februari",
    "Mars",
    "April",
    "Maj",
    "Juni",
    "Juli",
    "Augusti",
    "September",
    "Oktober",
    "November",
    "December",
];

export function getWeekDay(d: Date): string {
    return weekDay[d.getDay()];
}

export function getShortWeekDay(d: Date): string {
    return shortWeekDay[d.getDay()];
}

export function nowNormalized(): Date {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return now;
}

export function normalize(d: Date): Date {
    const newD = new Date(d);
    newD.setHours(0, 0, 0, 0);
    return newD;
}

export function parseDay(text: string): Date | undefined {
    const shortIndex = shortWeekDay.indexOf(text);
    if (shortIndex !== -1) {
        const now = nowNormalized();
        now.setHours(0, 0, 0, 0);
        const diff = shortIndex - now.getDay();
        return addDay(now, diff < 0 ? (diff + 7) : diff);
    }

    return undefined;
}

export function getWeekDayIndex(d: Date): number {
    const index = d.getDay();
    return index === 0 ? 6 : (index - 1);
}

export function getMonth(d: Date): string {
    return months[d.getMonth()];
}

export function addDay(d: Date, days: number): Date {
    const res = new Date(d);
    res.setDate(res.getDate() + days);
    return res;
}

export function isSameDay(a: Date, b: Date): boolean {
    return a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();
}

export function dateToString(date: Date): string {
    const m = ("" + (date.getMonth() + 1)).padStart(2, "0");
    const d = ("" + date.getDate()).padStart(2, "0");
    return `${date.getFullYear()}-${m}-${d}`;
}

export function datesInRange(start: Date, end: Date): Date[] {
    let dates: Date[] = [start];
    let curr: Date = start;
    while (!isSameDay(curr, end)) {
        curr = addDay(curr, 1);
        dates.push(curr);
    }

    return dates;
}
