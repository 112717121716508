import React, { useEffect, useMemo, useReducer, useState } from 'react';
import RecipesTable, { ColumnDefinition, RecipeRow } from './RecipesTable';
import { Button, IconButton, Input, InputAdornment, Menu, Stack } from '@mui/material';
import { TagsContext } from '../contexts/TagsContext';
import { EditRecipeDispatchContext, editRecipesReducer, Action as EditRecipeAction } from '../contexts/EditRecipeDispatch';
import IncludeExcludeFilterControl, { FilterState } from './IncludeExcludeFilterControl';
import useStickyState from '../contexts/StickyState';
import ClearIcon from '@mui/icons-material/Clear';


function toIncludeExclude(filteredTags: Map<string, FilterState>): [include: Set<string>, exclude: Set<string>] {
    const include = new Set<string>();
    const exclude = new Set<string>();
    for (const entry of filteredTags.entries()) {
        if (entry[1] === FilterState.Include) include.add(entry[0]);
        if (entry[1] === FilterState.Exclude) exclude.add(entry[0]);
    }
    return [include, exclude];
}

interface FilterMenuProps {
    tags: FilterableTag[];
    filteredTags: Map<string, FilterState>;
    onSetTag: (name: string, state: FilterState) => void;
}

function FilterMenu({ tags, filteredTags, onSetTag }: FilterMenuProps) {
    return <Stack direction='column' margin='15px'>
        {tags.map(({ name, count }) => {
            const label = `${name} (${count})`;
            const onChange = (filterState: FilterState) => {
                onSetTag(name, filterState);
            };
            return <IncludeExcludeFilterControl
                key={name}
                label={label}
                value={filteredTags.get(name)} onChange={onChange}
            ></IncludeExcludeFilterControl>;
        })}
    </Stack>;
}

export interface RecipesViewProps<RecipeT> {
    recipes: RecipeRow<RecipeT>[] | null;
    extraColumns?: ColumnDefinition<RecipeRow<RecipeT>>[];
}

interface FilterableTag {
    name: string;
    count: number;
}

function RecipesView<T>({ recipes, extraColumns }: RecipesViewProps<T>) {
    const [filterTags, setFilterTags] = useStickyState<Map<string, FilterState>>(
        new Map(),
        "recipes-filter",
        {
            encode(v) {
                return Array.from(v.entries())
            },
            decode(v) {
                return new Map(v)
            },
        }
    );
    const [query, setQuery] = useState<string>("");
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    let [context, dispatch] = useReducer<React.Reducer<RecipeRow<T>[] | null, EditRecipeAction<RecipeRow<T>> >>(editRecipesReducer, recipes);
    useEffect(() => {
        dispatch({ type: 'set', context: recipes });
    }, [recipes, dispatch]);

    const tags = useMemo<FilterableTag[]>(() => {
        const tags = new Map<string, number>();

        if (context) {
            for (const recipe of context) {
                if (!recipe.tags) continue;

                for (const tag of recipe.tags) {
                    tags.set(tag, (tags.get(tag) ?? 0) + 1);
                }
            }
        }

        return Array.from(tags.entries()).map(([name, count]) => ({ name, count }));
    }, [context]);
    const tagNames = useMemo(() => tags.map(t => t.name).sort((a, b) => a.localeCompare(b)), [tags]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const totalRecipes = context?.length ?? 0;

    if (context) {
        if (query) {
            const lowerQuery = query.toLowerCase();
            context = context.filter(r => r.name.toLowerCase().indexOf(lowerQuery) !== -1);
        }
        if (filterTags.size !== 0) {
            const [include, exclude] = toIncludeExclude(filterTags);
            if (include.size !== 0) {
                context = context.filter(r => r.tags?.some(t => include.has(t)));
            }
            if (exclude.size !== 0) {
                context = context.filter(r => !r.tags?.some(t => exclude.has(t)));
            }
        }
    }

    const displayedRecipes = context?.length ?? 0;

    return (<TagsContext.Provider value={tagNames}>
        <EditRecipeDispatchContext.Provider value={dispatch as any}>
            <Stack direction='row' spacing='5px' alignItems='center' justifyContent='end' marginRight='5px' paddingTop='5px' paddingBottom='5px'>
                <Button variant='outlined' href='/recipe-edit'>Nytt</Button>
                <Button variant='outlined' onClick={handleClick}>Filtrera</Button>
                <Menu
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                >
                    <FilterMenu
                        tags={tags}
                        filteredTags={filterTags}
                        onSetTag={(name, filterState) => {
                            setFilterTags(filterTags => {
                                if (filterState === FilterState.None) {
                                    filterTags.delete(name);
                                }
                                else {
                                    filterTags.set(name, filterState);
                                }

                                return new Map(filterTags);
                            });
                        }}
                    ></FilterMenu>
                </Menu>
                <Input
                    id="outlined-adornment-password"
                    type="text"
                    value={query}
                    onChange={event => setQuery(event.target.value)}
                    endAdornment={
                        <InputAdornment position="end" sx={{ visibility: query ? 'visible' : 'hidden'}}>
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setQuery("")}
                                edge="end"
                                >
                            {<ClearIcon />}
                            </IconButton>
                        </InputAdornment>
                    }
                    placeholder="Sök"
                />
                <div>Visar {displayedRecipes === totalRecipes ? displayedRecipes : `${displayedRecipes} av ${totalRecipes}`} recept</div>
            </Stack>
            <RecipesTable recipes={context} extraColumns={extraColumns}></RecipesTable>
        </EditRecipeDispatchContext.Provider>
    </TagsContext.Provider>);
}

export default RecipesView;
