import React, { useState, useCallback, useEffect, useMemo } from 'react';
import './PlanView.css';
import RecipesView from '../components/RecipesView';
import SelectPortions from '../components/SelectPortions';
import { LoaderFunctionArgs, useLoaderData, useNavigate } from 'react-router-dom';
import { datesInRange, getMonth, getWeekDay, isSameDay } from '../dates';
import { ColumnDefinition, RecipeRow } from '../components/RecipesTable';
import { PlanMeal, PlanMeals, Recipe, loadRecipes, planMeals, plannedMeals } from '../data';
import { AuthStatus, useAuthContext } from '../contexts/AuthProvider';
import { Box, Button, ButtonGroup, Stack, Typography } from '@mui/material';
import { RecipeServingTuple } from '../components/ShoppingListView';
import { Helmet } from 'react-helmet';

// Recipe id -> PlanEntry
type RecipeToPlanMap = Map<number, PlanEntry>;

interface LoaderData {
    dates: Date[];
}

export async function loader({ request }: LoaderFunctionArgs): Promise<LoaderData> {
    const url = new URL(request.url);
    const datesQuery = url.searchParams.get("dates") || "";
    const dateParts = datesQuery.split("_");
    const start = new Date(dateParts[0]);
    const end = new Date(dateParts[1]);
    const dates = datesInRange(start, end);
    return {dates};
}

interface IPlanDay {
    id: number;
    name: string;
}

interface SelectDayProps {
    days: IPlanDay[];
    disabled?: boolean;
    value?: number;
    onSelectDay?: (day?: IPlanDay | null) => void;
}

function SelectDay({ days, value, onSelectDay, disabled }: SelectDayProps) {
    const onChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        if (!onSelectDay) return;

        const valueStr = e.target.value;
        if (!valueStr) {
            onSelectDay(null);
            return;
        }
        const value = parseInt(valueStr, 10);
        const day = days.find(d => d.id === value);
        onSelectDay(day);
    }, [days, onSelectDay]);

    return <select onChange={onChange} value={value ?? 0} disabled={disabled}>
        <option value={0}>---</option>
        {days.map(d => {
            return <option key={d.id} value={d.id}>{d.name}</option>
        })}
    </select>;
}



function PlannedRecipe({ recipe }: { recipe: Recipe }) {
    return <div>
        {recipe.name}
    </div>;
}

function PlanDay({ date, recipes }: { date: Date; recipes: Recipe[] }) {
    const day = getWeekDay(date);
    const month = getMonth(date);

    let content: React.ReactElement;
    if (recipes.length) {
        content = <div className='plan-day-recipes'>
            {recipes.map((r, index) => <PlannedRecipe key={index} recipe={r}></PlannedRecipe>)}
        </div>;
    }
    else {
        content = <div className='plan-day-empty'>
            Inget planerat
        </div>;
    }

    return <div className='plan-day'>
        <div className='plan-day-header'>
            <div className='plan-day-header-day'>{day}</div>
            <div className='plan-day-header-date'>{date.getDate()} {month}</div>
        </div>
        {content}
    </div>;
}

function isPlannedMealsEqual(a: RecipeToPlanMap, b: RecipeToPlanMap): boolean {
    if (a === b) return true;
    if (a.size !== b.size) return false;

    for (const [recipeId, planA] of a.entries()) {
        const planB = b.get(recipeId);
        if (!planB) return false;

        if (planA.scale !== planB.scale || !isSameDay(planA.date, planB.date)) return false;
    }

    return true;
}

interface PlanEntry {
    date: Date;
    recipe: Recipe;
    scale: number;
}

interface RecipeExtra {
    plannedDay?: number;
    scaling?: number;
}

interface PlanViewContentProps {
    householdId: number;
    recipes: RecipeRow<RecipeExtra>[];
    dates: Date[];
    plannedMeals: RecipeToPlanMap;
    oldPlannedMeals: RecipeToPlanMap;
    setPlannedMeals: React.Dispatch<React.SetStateAction<RecipeToPlanMap>>;
    setOldPlannedMeals: React.Dispatch<React.SetStateAction<RecipeToPlanMap>>;
    canEdit?: boolean;
}

function PlanViewContent({ householdId, recipes, dates, plannedMeals, oldPlannedMeals, setPlannedMeals, setOldPlannedMeals, canEdit }: PlanViewContentProps) {
    const navigate = useNavigate();

    const hasPendingChanges = !isPlannedMealsEqual(plannedMeals, oldPlannedMeals);
    const planDays: IPlanDay[] = dates.map(d => {
        return { id: d.getTime(), name: `${getWeekDay(d)} ${d.getDate()}/${(d.getMonth() + 1)}`}
    });

    const plannedMealsValues = Array.from(plannedMeals.values());
    const plannedRecipes = new Map<number, { date: number; scaling: number; }>();
    for (const { recipe, date, scale } of plannedMealsValues) {
        plannedRecipes.set(recipe.id, { date: date.getTime(), scaling: scale });
    }

    for (const recipe of recipes) {
        const entry = plannedRecipes.get(recipe.id);
        recipe.plannedDay = entry?.date;
        recipe.scaling = entry?.scaling;
    }

    const onSave = async () => {
        let request: PlanMeals[] = [];
        for (const date of dates) {
            const planned = plannedMealsValues
                .filter(e => e.date === date)
                .map<PlanMeal>(e => ({ recipe_id: e.recipe.id, servings: e.scale }));

            request.push({ date, planned });
        }

        try {
            await planMeals(householdId, request);
            setOldPlannedMeals(plannedMeals);
        }
        catch (e) {
            console.error("Planned meals failed", e);
        }
    };

    const onSelectDay = useCallback((recipe: RecipeRow<RecipeExtra>, day?: IPlanDay | null) => {
        if (!day) {
            setPlannedMeals(plannedMeals => {
                const copy = new Map(plannedMeals);
                copy.delete(recipe.id);
                return copy;
            });
            return;
        }

        const date = dates.find(d => d.getTime() === day.id);
        if (date) {
            setPlannedMeals(plannedMeals => {
                const copy = new Map(plannedMeals);
                copy.set(recipe.id, { recipe, date, scale: recipe.servings ?? 1 });
                return copy;
            });
        }
    }, [dates]);

    const onSelectPortion = useCallback((recipe: RecipeRow<RecipeExtra>, scale: number) => {
        setPlannedMeals(plannedMeals => {
            const entry = plannedMeals.get(recipe.id);
            if (entry) {
                const copy = new Map(plannedMeals);
                copy.set(recipe.id, { ...copy.get(recipe.id)!, scale: scale });
                return copy;
            }

            return plannedMeals;
        });
    }, []);

    const onShowShoppingList = () => {
        let recipesToShop: RecipeServingTuple[] = [];
        for (const entry of plannedMeals.values()) {
            recipesToShop.push([entry.recipe.id, entry.date.getTime(), entry.scale]);
        }

        const meals = encodeURIComponent(JSON.stringify(recipesToShop.sort((a, b) => a[1] - b[1])));
        navigate(`/shopping-list?meals=${meals}`);
    };

    const extraColumns: ColumnDefinition<RecipeRow<RecipeExtra>>[] = useMemo(() => [
        {
            name: "Dag",
            build: recipe => <SelectDay days={planDays} value={recipe.plannedDay} onSelectDay={day => onSelectDay(recipe, day)} disabled={!canEdit}></SelectDay>,
            sort: (a, b) => (a.plannedDay ?? -1) - (b.plannedDay ?? -1)
        },
        {
            name: "Portioner",
            build: recipe => typeof recipe.plannedDay === "number" ?
                <SelectPortions scaling={recipe.scaling ?? 1} onSelectPortion={scale => onSelectPortion(recipe, scale)} disabled={!canEdit}></SelectPortions> :
                <></>,
            sort: (a, b) => (a.scaling ?? -1) - (b.scaling ?? -1)
        }
    ], [planDays, canEdit]);

    return <Stack
        direction='row'
        sx={{
            minHeight: "100%",
            flexGrow: '1',
            position: 'relative'
        }}
    >
        <Helmet>
            <title>Planera</title>
        </Helmet>
        <Box
            sx={{
                position: 'absolute',
                height: '100%',
                left: 0,
                overflowY: 'scroll',
                width: '14.9%'
            }}
        >
            <Stack direction='column' padding='15px'>
                <Typography variant='h6' marginBottom='15px'>
                    Att planera
                </Typography>
                {dates.map((d, index) => {
                    const recipes = plannedMealsValues
                        .filter(e => e.date === d)
                        .map(e => e.recipe);
                    return <PlanDay key={index} date={d} recipes={recipes}></PlanDay>;
                })}
                <Stack direction='row'>
                    <ButtonGroup variant='contained'>
                        <Button onClick={onSave} disabled={!canEdit || !hasPendingChanges}>Spara</Button>
                        <Button disabled={hasPendingChanges} onClick={onShowShoppingList}>Inköpslista</Button>
                    </ButtonGroup>
                </Stack>
            </Stack>
        </Box>
        <Box
            sx={{
                position: 'absolute',
                height: '100%',
                right: 0,
                overflowY: 'scroll',
                width: '85%',
            }}
        >
            <Box paddingBottom='30px'>
                <RecipesView recipes={recipes} extraColumns={extraColumns}></RecipesView>
            </Box>
        </Box>
    </Stack>;
}


function PlanView() {
    const { dates } = useLoaderData() as LoaderData;
    const auth = useAuthContext();
    const currentHouseholdId = auth.status === AuthStatus.SignedIn ? auth.currentHousehold : undefined;
    const householdPermission = auth.status === AuthStatus.SignedIn ? auth.user.households.find(h => h.id === currentHouseholdId)?.relation : undefined;
    const canEdit = householdPermission === "Owner" || householdPermission === "Writer";

    const [recipes, setRecipes] = useState<Recipe[] | undefined>();
    const [plannedMealsMap, setPlannedMealsMap] = useState<RecipeToPlanMap | undefined>();
    const [oldPlannedMealsMap, setOldPlannedMealsMap] = useState<RecipeToPlanMap | undefined>();

    useEffect(() => {
        let cleanedUp = false;
        Promise.all([
            loadRecipes(currentHouseholdId),
            plannedMeals(currentHouseholdId, dates[0], dates[dates.length - 1])
        ])
        .then(([recipes,  plannedMeals]) => {
            if (cleanedUp) return;

            const plannedMealsMap = new Map<number, PlanEntry>();
            for (const meal of plannedMeals) {
                const mealDate = new Date(meal.date);
                const foundDate = dates.find(d => isSameDay(d, mealDate));
                if (foundDate) {
                    plannedMealsMap.set(meal.recipe.id, { date: foundDate, recipe: meal.recipe, scale: meal.servings ?? 1 });
                }
            }
            setRecipes(recipes);
            setPlannedMealsMap(plannedMealsMap);
            setOldPlannedMealsMap(plannedMealsMap);
        });

        return () => { cleanedUp = true; };
    }, [currentHouseholdId, dates]);

    if (!recipes || !plannedMealsMap || currentHouseholdId === undefined) {
        return <div>Loading...</div>;
    }

    return <PlanViewContent
        householdId={currentHouseholdId}
        dates={dates}
        recipes={recipes}
        plannedMeals={plannedMealsMap}
        oldPlannedMeals={oldPlannedMealsMap!}
        setPlannedMeals={setPlannedMealsMap as any}
        setOldPlannedMeals={setOldPlannedMealsMap as any}
        canEdit={canEdit}
    ></PlanViewContent>;
}

export default PlanView;
