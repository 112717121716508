import { Button, ButtonPropsVariantOverrides, CircularProgress, Stack } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { PropsWithChildren } from "react";

export interface PendingButtonProps {
    onClick?: () => void;
    disabled?: boolean;
    pending?: boolean;
    variant?: OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>
}

export default function PendingButton({ children, onClick, disabled, pending, variant }: PropsWithChildren<PendingButtonProps>) {
    return <Button onClick={onClick} disabled={pending || disabled} variant={variant}>
        <Stack direction='row' spacing='10px'>
            <div>{children}</div>
            {pending ? <CircularProgress size={20}/> : <></>}
        </Stack>
    </Button>;
}
