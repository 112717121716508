import * as date from "./dates";

export type HouseholdRelation = "Owner" | "Writer" | "Reader";

export interface Household {
    id: number;
    relation: HouseholdRelation;
    name: string;
}

export interface User {
    id: number;
    name: string;
    avatar_image_id?: number;
    households: Household[];
    editable_users: number[];
}

export interface HouseholdUser {
    id: number;
    name: string;
    avatar_image_id?: number;
    relation: HouseholdRelation;
}

export interface Recipe {
    id: number;
    author_id: number;
    name: string;
    image_id?: number;
    link?: string;
    total_time: number;
    servings?: number;
    tags?: string[];
    last_planned_date?: string;
}

export interface RecipeFull extends Recipe {
    recipe: string;
}

export interface RecipeImage {
    step: number;
    image_id: number;
}

export interface RecipeWithImages {
    recipe: RecipeFull;
    images: RecipeImage[];
}

export interface SaveRecipe {
    id?: number;
    name: string;
    images: (number | null)[];
    recipe: string;
}

export interface SaveRecipeTags {
    id: number;
    tags: string[];
}

export interface PlanMeal {
    recipe_id: number;
    servings: number;
}

export interface PlanMeals {
    date: Date;
    planned: PlanMeal[];
}

export interface PlannedMeal {
    id: number;
    date: string;
    recipe: Recipe;
    servings?: number;
}

async function postJson<Res = unknown, Body = any>(url: string, body: Body): Promise<Res> {
    const request = new Request(url, {
        method: "POST",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    });
    const res = await fetch(request);
    return res.json();
}

async function postJsonVoid<Body = any>(url: string, body: Body): Promise<void> {
    const request = new Request(url, {
        method: "POST",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    });
    const res = await fetch(request);
    if (res.status !== 200) {
        throw new Error(`Invalid response ${res.status}`);
    }
}

async function getJson<Res = unknown>(url: string): Promise<Res> {
    const request = new Request(url, {
        method: "GET",
        credentials: "same-origin",
    });
    const res = await fetch(request);
    return res.json();
}

export function imageIdToUrl(size: "thumb" | "medium" | "large", imageId?: number): string | undefined {
    if (size === "medium") {
        size = "large";
    }
    if (typeof imageId === "number") {
        return `/image/${imageId}/${size}`;
    }

    return undefined;
}

export async function getUser(): Promise<User | undefined> {
    const request = new Request("/api/user", {
        method: "GET",
        credentials: "same-origin",
    });
    const res = await fetch(request);
    if (res.status === 401) {
        return undefined;
    }
    else if (res.status !== 200) {
        throw new Error(`Invalid response from server: ${res.status}`);
    }
    return res.json();
}

export async function setUserAvatar(avatarImageId: number): Promise<void> {
    return postJson(`/api/user/avatar`, avatarImageId);
}

export interface Integration {
    id: number;
    api: "tick_tick";
    name?: string;
}

export async function getUserIntegrations(): Promise<Integration[]> {
    return getJson(`/api/user/integrations`);
}

export async function deleteIntegration(id: number): Promise<boolean> {
    const request = new Request(`/api/user/integration/${id}`, {
        method: "DELETE",
        credentials: "same-origin",
    });
    const res = await fetch(request);
    return res.ok;
}

export interface TickTickList {
    id: string;
    name: string;
    color?: string;
}

export interface TickTick {
    id: number;
    name?: string;
    lists: TickTickList[];
}

export interface NewTickTickTask {
    title: string;
    isAllDay?: boolean;
    content?: string;
    desc?: string;
    sortOrder?: number;
}

export interface UpdateTickTickTask {
    id: string;
    title?: string;
    isAllDay?: boolean;
    content?: string;
    desc?: string;
    sortOrder?: number;
}

export interface TickTickTask {
    id: string;
    projectId: string;
    title: string;
    isAllDay: boolean;
    content?: string;
    desc?: string;
    sortOrder: number;
}

export async function getUserTickTickLists(): Promise<TickTick[]> {
    return getJson(`/api/user/shopping-lists`);
}

export async function getUserTickTickList(tickTickId: number, listId: string): Promise<TickTickTask[]> {
    return getJson(`/api/user/shopping-list/${tickTickId}/${listId}`);
}

export async function addToTickTickList(
    tickTickId: number,
    listId: string,
    tasks: (NewTickTickTask | UpdateTickTickTask)[]
): Promise<(TickTickTask | undefined | null)[]> {
    return postJson(`/api/user/shopping-list/${tickTickId}/${listId}`, tasks);
}

export async function getHouseholdUsers(householdId: number): Promise<HouseholdUser[]> {
    return getJson(`/api/household/${householdId}/users`);
}

export interface HouseholdSave {
    name?: string;
}

export async function editHousehold(householdId: number, data: HouseholdSave): Promise<void> {
    return postJson(`/api/household/${householdId}`, data);
}

export async function inviteToHousehold(householdId: number): Promise<string> {
    return postJson(`/api/household/${householdId}/create-invite`, {});
}

export interface InviteInfo {
    household_name: string;
}

export async function viewInviteToHousehold(token: string): Promise<InviteInfo | undefined> {
    const request = new Request(`/api/view-invite/${token}`, {
        method: "GET",
        credentials: "same-origin",
    });
    const res = await fetch(request);
    if (res.status === 404) return undefined;

    return res.json();
}

export async function acceptInviteToHousehold(token: string): Promise<void> {
    return postJson(`/api/accept-invite/${token}`, {});
}

export async function householdRemoveUser(householdId: number, userId: number): Promise<HouseholdUser[]> {
    return postJson(`/api/household/${householdId}/user/${userId}/remove`, {});
}

export async function loadRecipes(household_id?: number): Promise<Recipe[]> {
    return getJson(`/api/recipes/${household_id === undefined ? "" : household_id}/all`);
}

export async function loadRecipeRaw(id: number): Promise<RecipeWithImages | undefined> {
    const request = new Request(`/api/recipe/${id}`, {
        method: "GET",
        credentials: "same-origin",
    });
    const res = await fetch(request);
    if (res.status === 404) {
        return undefined;
    }
    return res.json();
}

/**
 * @returns Id of recipe which was updated or created.
 */
export async function saveRecipe(recipe: SaveRecipe): Promise<number> {
    const obj = {
        id: recipe.id,
        name: recipe.name,
        images: recipe.images,
        recipe: recipe.recipe
    };

    return postJson("/api/recipe", obj);
}

export async function saveRecipeTags(recipe: SaveRecipeTags): Promise<void> {
    const obj = {
        id: recipe.id,
        tags: recipe.tags,
    };

    return postJsonVoid("/api/recipe/tags", obj);
}

export function dateToString(d?: Date): string | undefined {
    if (!d) return undefined;

    return date.dateToString(d);
}

export async function plannedMeals(householdId?: number, start?: Date, end?: Date, recipeId?: number): Promise<PlannedMeal[]> {
    const obj = {
        household_id: householdId,
        recipe_id: recipeId,
        start: dateToString(start),
        end: dateToString(end)
    };

    return postJson("/api/planned-meals", obj);
}

export async function planMeals(householdId: number, plan: PlanMeals[]): Promise<void> {
    const obj = {
        household_id: householdId,
        plan_meals: plan.map(p => ({
            date: dateToString(p.date),
            planned: p.planned
        }))
    };

    return postJson("/api/plan-meals", obj);
}

export interface NumberRegular {
    type: "regular";
    value: number;
}

export interface NumberFraction {
    type: "fraction";
    value: {
        whole: number;
        num: number;
        den: number;

        err: number;
    }
}

export type CooklangNumber = NumberRegular | NumberFraction;

export interface QuantityNumber {
    type: "number";
    value: CooklangNumber;
}

export interface QuantityRange {
    type: "range";
    start: CooklangNumber;
    end: CooklangNumber;
}

export interface Text {
    type: "text";
    value: string;
}

export type QuantityValue = QuantityNumber | QuantityRange | Text;

export interface FixedValue {
    type: "fixed";
    value: QuantityValue;
}

export interface LinearValue {
    type: "linear";
    value: QuantityValue;
}

export interface ByServingsValue {
    type: "byServings";
    value: QuantityValue[];
}

export type ScalableValue = FixedValue | LinearValue | ByServingsValue;

export interface Quantity {
    value: ScalableValue;
    unit?: string;
}

export interface Ingredient {
    name: string;
    quantity?: QuantityValue;
    unit?: string;
    scale_outcome: 'none' | 'scaled' | 'fixed' | 'noQuantity' | 'error';
}

export interface IngredientsCategory {
    id?: number;
    name: string | null;
    items: Ingredient[];
}

export interface RecipeWithIngredients {
    recipe: Recipe;
    ingredients: Ingredient[];
}

export interface ShopMeal {
    recipe_id: number;
    servings?: number;
}

export interface ShoppingList {
    recipes: RecipeWithIngredients[];
    list: IngredientsCategory[];
}

export async function shoppingList(recipes_to_shop: ShopMeal[]): Promise<ShoppingList> {
    return postJson("/api/shopping-list", recipes_to_shop);
}

export interface AisleWithIngredients {
    id: number;
    name: string;
    sort_order: number;
    ingredients: string[];
}

export async function getIngredients(): Promise<AisleWithIngredients[]> {
    const request = new Request("/api/ingredients", {
        method: "GET",
        credentials: "same-origin",
    });
    const res = await fetch(request);
    return res.json();
}

export async function categorizeIngredient(categoryId: number, ingredient: string): Promise<number> {
    const obj = {
        aisle_id: categoryId,
        ingredient: ingredient
    };
    return postJson("/api/categorize-ingredient", obj);
}

export interface SaveCategory {
    id?: number;
    name: string;
    sort_order: number;
}

export async function saveCategories(categories: SaveCategory[]): Promise<number[]> {
    return postJson("/api/save-categories", categories);
}

export interface DeleteCategory {
    id: number;
    move_ingredients_to_aisle_id?: number;
}

export async function deleteCategories(categories: DeleteCategory[]): Promise<void> {
    return postJson("/api/delete-categories", categories);
}

export interface RecipeIngredient {
    name: string;
    quantity?: Quantity;
}

export interface Cookware {
    name: string;
    quantity?: QuantityValue;
}

export interface Timer {
    name?: string;
    quantity?: Quantity;
}

export interface IndexReference<T extends string> {
    type: T;
    index: number;
}

export type StepItem =
    Text |
    IndexReference<"ingredient"> |
    IndexReference<"cookware"> |
    IndexReference<"timer"> |
    IndexReference<"inlineQuantity">;

export interface Step {
    type: "step";
    value: {
        items: StepItem[];
        number: number;
    }
}

export type Content = Step | Text;

export interface Section {
    name?: string;
    content: Content[];
}

export interface Metadata {
    map: {
        [key: string]: string;
    };
    special: {
        servings?: number[];
        time?: number | { prep_time: number; cook_time: number; };
        source?: { name?: string; url?: string; }
    };
}

export interface CooklangRecipe {
    metadata: Metadata;
    sections: Section[];
    cookware: Cookware[];
    ingredients: RecipeIngredient[];
    timers: Timer[];
}

export interface StepImage {
    step: number;
    image_id: number;
}

export interface CooklangRecipeWithMetadata {
    name: string;
    recipe: CooklangRecipe;
    image_id?: number;
    step_images: StepImage[];
}

export function emptyRecipe(): CooklangRecipe {
    return {
        metadata: { map: {}, special: {} },
        cookware: [],
        ingredients: [],
        sections: [],
        timers: []
    };
}

export async function cookRecipe(recipeId: number): Promise<CooklangRecipeWithMetadata | undefined> {
    const request = new Request(`/api/cook/recipe/${recipeId}`, {
        method: "GET",
        credentials: "same-origin",
    });
    const res = await fetch(request);
    if (res.status === 404) {
        return undefined;
    }
    return res.json();
}

export async function uploadImage(data: File): Promise<number> {
    const request = new Request("/upload-image", {
        method: "POST",
        credentials: "same-origin",
        body: data
    });
    const res = await fetch(request);
    return res.json();
}
