import React, { CSSProperties, ReactElement, useState } from 'react';

import editIcon from "../icons/edit_icon.svg"
import './RecipesTable.css';
import { Recipe, dateToString } from "../data";
import LinkButton from './LinkButton';
import { getMonth, getWeekDay } from '../dates';
import { AuthStatus, useAuthContext } from '../contexts/AuthProvider';
import Stack from '@mui/material/Stack';
import { Autocomplete, Checkbox, createFilterOptions, Divider, FormControlLabel, IconButton, Menu, TextField } from '@mui/material';
import { useTagsContext } from '../contexts/TagsContext';
import { capitalizeFirst } from '../textFormattings';
import { useEditRecipeDispatch } from '../contexts/EditRecipeDispatch';
import { Link } from 'react-router-dom';

export function buildRecipeName(recipe: Recipe): JSX.Element {
    const link = "/recipe/" + recipe.id;
    return <span><Link className='recipe-name' to={link}>{recipe.name}</Link></span>
}

export function buildEditRecipe(recipe: { id: number; }): JSX.Element {
    return <div className='recipe-action-button'>
        <LinkButton href={"/recipe-edit?id=" + recipe.id}>
            <img src={editIcon} className='recipe-action-button' alt='edit'></img>
        </LinkButton>
    </div>;
}

enum Sort {
    Asc,
    Desc
}

function invertSort(sort: Sort | undefined): Sort {
    if (sort === Sort.Asc) {
        return Sort.Desc;
    }

    return Sort.Asc;
}

interface ColumnHeadProps {
    text: string;
    sort?: Sort;
    buildHead?: () => ReactElement;
    onSort: (order: Sort) => void;
}

function ColumnHead({ text, sort, onSort, buildHead }: ColumnHeadProps) {
    return <div className="recipe-table-column-head" onClick={() => onSort(invertSort(sort))}>
        <div style={{ flexGrow: 1 }}>{text}</div>
        {buildHead?.()}
        <div style={{ width: 10 }}>{sort === Sort.Asc ? "↑" : (sort === Sort.Desc ? "↓" : undefined)}</div>
    </div>;
}

const filter = createFilterOptions<string>();

function AddTagTextInput({ onSetTag }: { onSetTag?: (tag: string | null) => void}) {
    const tags = useTagsContext();
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const closePopper = () => setOpen(false);
    const openPopper = () => setOpen(true);

    const onSetTagInner = (tag: string | null) => {
        onSetTag?.(tag);
        closePopper();
        setInputValue("");
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            onSetTagInner((e.target as HTMLInputElement).value);
            (e.target as HTMLInputElement).blur();
        }
    };
    return (
        <Autocomplete
            open={open}
            value={inputValue}
            inputValue={inputValue}
            onInputChange={(_, value) => setInputValue(value)}
            onOpen={openPopper}
            onClose={closePopper}
            onKeyDown={e => e.stopPropagation()}
            onChange={(_, newValue) => {
                onSetTagInner(newValue);
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option);
                if (inputValue !== '' && !isExisting) {
                    filtered.push(inputValue);
                }

                return filtered;
            }}
            selectOnFocus
            handleHomeEndKeys
            id="add-ingredient-input"
            options={tags}
            getOptionLabel={(option) => {
                return option;
            }}
            renderOption={(props, option) => <li {...props}>{option}</li>}
            sx={{ width: 300 }}
            freeSolo
            renderInput={(params) => (
                <TextField {...params} onKeyDown={onKeyDown} label="Lägg till kategori" sx={{ marginBottom: "15px"}}/>
            )}
        />
    );
}

function TagsCell({ recipeId, tags }: { recipeId: number; tags: string[]; }) {
    const dispatch = useEditRecipeDispatch();
    const knownTags = useTagsContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [menuCreated, setMenuCreated] = useState<boolean>(false);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setMenuCreated(true);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSetTag = (tag: string | null) => {
        if (!tag) return;

        let newTags = [...tags, tag];
        dispatch({ type: 'tags', recipeId, tags: newTags });
    };

    const onCheckTag = (tag: string, checked: boolean) => {
        if (checked) {
            if (tags.indexOf(tag) === -1) {
                let newTags = [...tags, tag];
                dispatch({ type: 'tags', recipeId, tags: newTags });
            }
        }
        else {
            let newTags = tags?.filter(t => t !== tag) || [];
            dispatch({ type: 'tags', recipeId, tags: newTags });
        }
    };

    let menu: ReactElement | undefined;
    if (menuCreated) {
        menu = <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            onTransitionExited={() => setMenuCreated(false)}
        >
            <Stack direction='column' margin='15px'>
                <AddTagTextInput onSetTag={onSetTag}></AddTagTextInput>
                <Divider></Divider>
                {knownTags.map(tag => {
                    return <FormControlLabel
                        key={tag}
                        control={<Checkbox checked={tags.some(t => t === tag)} onChange={(e, checked) => onCheckTag(tag, checked)}/>}
                        label={tag}
                    ></FormControlLabel>
                })}
            </Stack>
        </Menu>;
    }

    return <div style={{ height: "100%" }}>
        <Stack
            direction='row'
            sx={{ ":hover": {cursor: "pointer"} }}
            spacing='5px'
            onClick={handleClick}
            flexWrap='wrap'
            useFlexGap
            height='100%'
        >
                {tags?.map((tag) => <span key={tag} className='recipe-tag'>{tag}</span>)}
        </Stack>
        {menu}
    </div>;
}

function plural(count: number, singular: string, plural: string): string {
    if (Math.abs(count) < 2) return singular;

    return plural;
}

const MS_PER_DAY = 1000 * 60 * 60 * 24;
function LastCooked({ today, date }: { today: Date, date?: string; }) {
    if (!date) {
        return <span className='recipe-last-cook never'>Aldrig</span>;
    }

    const d = new Date(date);
    const title = `${getWeekDay(d)} ${d.getDate()} ${getMonth(d)}`;
    const diff = Math.round((today.getTime() - d.getTime()) / MS_PER_DAY);
    const days = plural(diff, "dag", "dagar");
    if (diff < 0) {
        // It is planned in the future
        return <span className='recipe-last-cook future' title={title}>om {-diff} {days}</span>;
    }

    if (diff < 7) {
        return <span className='recipe-last-cook recent' title={title}>{diff} {days} sen</span>;
    }

    const weeks = Math.floor(diff / 7);
    return <span className='recipe-last-cook past' title={title}>{weeks} {plural(weeks, "vecka", "veckor")} sen</span>;
}

export type RecipeRow<T> = Recipe & T;
export interface RecipesTableProps<RecipeT> {
    recipes: RecipeRow<RecipeT>[] | null;
    extraColumns?: ColumnDefinition<RecipeRow<RecipeT>>[];
}

export interface ColumnDefinition<T> {
    name: string;
    build: (row: T) => ReactElement;
    buildHead?: () => ReactElement;
    sort: (rowA: T, rowB: T) => number;
    columnStyle?: CSSProperties
}

function RecipesTable<RecipeT>({ recipes, extraColumns }: RecipesTableProps<RecipeT>) {
    const authState = useAuthContext();
    const [sort, setSort] = useState<{ column: string; order: Sort; } | undefined>(undefined);
    const editableUsers = authState.status === AuthStatus.SignedIn ? authState.editableUsers : new Set();

    if (!recipes) {
        return (<div>Loading...</div>);
    }

    const today = new Date(dateToString(new Date())!);

    const columns: ColumnDefinition<RecipeRow<RecipeT>>[] = [
        ...(extraColumns || []),
        {
            name: "Namn",
            build: buildRecipeName,
            sort: (a, b) => a.name.localeCompare(b.name)
        },
        {
            name: "Tillagningstid",
            build: ({ total_time }) => {
                const durationClass = total_time > 45 ? "long" :
                                      total_time > 30 ? "medium" :
                                      "short";
                return <div className={"recipe-cook " + durationClass}>{total_time} min</div>;
            },
            sort: (a, b) => a.total_time - b.total_time
        },
        {
            name: "Kategori",
            build: recipe => <TagsCell recipeId={recipe.id} tags={recipe.tags || []}></TagsCell>,
            sort: (a, b) => (a.tags?.length || 0) - (b.tags?.length || 0),
            columnStyle: { width: "10%" }
        },
        {
            name: "Senast lagad",
            build: recipe => <LastCooked today={today} date={recipe.last_planned_date}></LastCooked>,
            sort: (a, b) => (a.last_planned_date || "").localeCompare(b.last_planned_date || "")
        },
        {
            name: "Källa",
            build: recipe => <div className='recipe-source'><a href={recipe.link}>{recipe.link}</a></div>,
            sort: (a, b) => (a.link || "").localeCompare(b.link || ""),
            columnStyle: { width: "30%"}
        },
        {
            name: "",
            build: recipe => editableUsers.has(recipe.author_id) ? buildEditRecipe(recipe) : <></>,
            sort: (a, b) => (a.link || "").localeCompare(b.link || "")
        },
    ];

    let sortedRecipes = recipes.slice();
    if (sort) {
        const column  = columns.find(c => c.name === sort.column);
        if (column) {
            if (sort.order === Sort.Asc) {
                sortedRecipes.sort(column.sort);
            }
            else {
                sortedRecipes.sort((a, b) => column.sort(b, a));
            }
        }
    }

    return (<table style={{ height: '1px' }}>
        <thead>
            <tr>
                {columns.map((column, index) => {
                    const onSort = (order: Sort) => {
                        setSort({ column: column.name, order });
                    };

                    return <th key={index} style={column.columnStyle}>
                        <ColumnHead
                            key={index}
                            text={column.name}
                            sort={sort?.column === column.name ? sort.order : undefined}
                            onSort={onSort}></ColumnHead>
                    </th>;
                })}
            </tr>
        </thead>
        <tbody>
            {sortedRecipes.map(recipe => {
                return (
                    <tr key={recipe.id} className='recipe-table-column-row'>
                        {columns.map((column) => <td key={column.name}>{column.build(recipe)}</td>)}
                    </tr>
                );
            })}
        </tbody>
    </table>);
}

export default RecipesTable;
