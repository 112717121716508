import React, { useEffect, useState } from 'react';
import editIcon from "../icons/edit_icon.svg";
import cancelIcon from "../icons/cancel.png";
import acceptIcon from "../icons/accept.png";
import { AuthStatus, useAuthContext, useAuthContextControl } from '../contexts/AuthProvider';
import { Household, HouseholdUser, Integration, User, deleteIntegration, editHousehold, getHouseholdUsers, getUserIntegrations, getUserTickTickLists, householdRemoveUser, imageIdToUrl, inviteToHousehold, setUserAvatar, uploadImage } from '../data';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Paper, TextField } from '@mui/material';
import ImageUploading, { ImageListType, ImageType } from 'react-images-uploading';
import tickTickLogo from "../icons/ticktick-Logo-horizontal-small.png";
import { Helmet } from 'react-helmet';
import EditableText from './EditableText';
import AcceptOrCancel from './AcceptOrCancel';

function HouseholdUserView({ user, removeAllowed, onRemove }: { user: HouseholdUser; removeAllowed: boolean; onRemove: () => void }) {
    const showRemove = removeAllowed && user.relation !== "Owner";

    return <Paper sx={{ padding: "10px" }}>
        <Stack direction="row" alignItems="center" useFlexGap spacing="15px">
            <Avatar alt={user.name} src={imageIdToUrl("thumb", user.avatar_image_id)} sx={{ width: 30, height: 30 }}/>
            <div>{user.name}</div>
            <div>(<b>{user.relation}</b>)</div>
            {showRemove ? <Button onClick={onRemove}>Remove</Button> : <></>}
        </Stack>
    </Paper>;
}

interface HouseholdViewProps {
    household: Household;
    users: HouseholdUser[];
    me: User;
    onChangeHouseholdName: (newName: string) => Promise<void>;
    onRemoveUser: (userId: number) => Promise<void>;
}

function HouseholdView({ household, me, users, onChangeHouseholdName, onRemoveUser }: HouseholdViewProps) {
    const [isCreatingInvite, setIsCreatingInvite] = useState<boolean>(false);
    const [inviteUrl, setInviteUrl] = useState<string | undefined>();
    const onCreateInvite = async () => {
        setIsCreatingInvite(true);
        const url = await inviteToHousehold(household.id);
        setInviteUrl(`${window.location.origin}${url}`);
        setIsCreatingInvite(false);
    };

    const inviteContent = inviteUrl ?
        <TextField InputProps={{ readOnly: true }} label="Invite url" defaultValue={inviteUrl} fullWidth></TextField> :
        <Button variant='contained' onClick={onCreateInvite} disabled={isCreatingInvite}>Create Invite</Button>;

    const amIOwner = users.find(u => u.id === me.id)?.relation === "Owner";

    return <Paper sx={{ padding: "15px" }}>
        <EditableText value={household.name} onCommit={onChangeHouseholdName} disabled={!amIOwner}></EditableText>
        <Stack direction="column" alignItems="flex-start" spacing="5px">
            {users.map(u => {
                const onRemove = () => onRemoveUser(u.id);
                return <HouseholdUserView key={u.id} user={u} removeAllowed={amIOwner} onRemove={onRemove}></HouseholdUserView>;
            })}
            {inviteContent}
        </Stack>
    </Paper>;
}

function EditableAvatar({ name, avatar_image_id }: { name: string; avatar_image_id: number | undefined; }) {
    const authControl = useAuthContextControl();
    const [image, setImage] = useState<ImageType | undefined>(
        avatar_image_id ? { avatar_image_id } : undefined
    );
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const hasPending = !!(image?.['data_url']);

    const onChange = async (imageList: ImageListType, addUpdateIndex?: number[] | undefined) => {
        // data for submit
        let image = imageList[0];
        setImage(image);

        if (image?.file) {
            image.avatar_image_id = await uploadImage(image.file);
        }
    };

    const onSave = async () => {
        setIsDisabled(true);
        try {
            const image_id: number = image!.avatar_image_id;
            await setUserAvatar(image_id);
            await authControl?.refresh();
            setImage(avatar_image_id ? { avatar_image_id: image_id } : undefined);
        }
        finally {
            setIsDisabled(false);
        }
    };

    const onCancel = () => {
        setImage(avatar_image_id ? { avatar_image_id } : undefined);
    };

    return <Stack alignItems="end">
        <ImageUploading
            value={image ? [image] : []}
            onChange={onChange}
            dataURLKey='data_url'
            maxFileSize={2048*1024}>
            {
                ({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                }) => {
                    let classNames = ["upload_image_button-wrapper"];
                    if (isDragging) {
                        classNames.push("dragging");
                    }

                    const image = imageList[0];
                    const imageUrl = image?.['data_url'] || imageIdToUrl("large", image?.['avatar_image_id']);
                    // write your building UI
                    return <Avatar
                        alt={name}
                        src={imageUrl}
                        onClick={isDisabled ? undefined : onImageUpload}
                        sx={{ width: 200, height: 200 }}
                    />;
                }
            }
        </ImageUploading>
        {hasPending ? <AcceptOrCancel onAccept={onSave} onCancel={onCancel} isDisabled={isDisabled}></AcceptOrCancel> : <></>}
    </Stack>
}

export default function ProfileView() {
    const auth = useAuthContext();
    const authControl = useAuthContextControl();
    const [householdUsers, setHouseholdUsers] = useState<Map<number, HouseholdUser[]>>(new Map());
    const [integrations, setIntegrations] = useState<Integration[]>([]);
    
    useEffect(() => {
        if (auth.status !== AuthStatus.SignedIn) {
            return;
        }

        const user = auth.user;
        for (const household of user.households) {
            getHouseholdUsers(household.id).then(users => {
                setHouseholdUsers(householdUsers => {
                    householdUsers.set(household.id, users);
                    return new Map(householdUsers)
                });
            });
        }

        getUserIntegrations().then(setIntegrations);
    }, [auth.status]);
    
    if (auth.status !== AuthStatus.SignedIn) {
        return <div></div>;
    }

    const onDelete = (integration: Integration) => {
        deleteIntegration(integration.id)
            .then(getUserIntegrations)
            .then(setIntegrations);
    };

    const user = auth.user;

    return <div>
        <Helmet>
            <title>Profil</title>
        </Helmet>
        <Stack direction="row" sx={{ margin: "20px" }} spacing="20px">
            <Stack>
                <EditableAvatar name={user.name} avatar_image_id={user.avatar_image_id}></EditableAvatar>
                <h2>{user.name}</h2>
            </Stack>
            <Stack sx={{ flexGrow: 1 }}>
                <h2>Hushåll</h2>
                {user.households.map(h => {
                    const users = householdUsers.get(h.id) ?? [];
                    const onChangeHouseholdName = async (newName: string) => {
                        await editHousehold(h.id, { name: newName });
                        await authControl?.refresh();
                    };
                    const onRemoveUser = async (userId: number) => {
                        const newUserList = await householdRemoveUser(h.id, userId);
                        setHouseholdUsers(householdUsers => {
                            householdUsers.set(h.id, newUserList);
                            return new Map(householdUsers);
                        });
                    };

                    return <HouseholdView
                        key={h.id}
                        household={h}
                        me={user}
                        users={users}
                        onChangeHouseholdName={onChangeHouseholdName}
                        onRemoveUser={onRemoveUser}
                    ></HouseholdView>;
                })}
                <h2>Integrationer</h2>
                <Stack direction='column' spacing='15px'>
                    {
                        integrations.map(integration => {
                            return <Paper sx={{ padding: "15px" }}>
                                <Stack direction='row' alignItems='center' spacing='15px'>
                                    <img src={tickTickLogo} loading='lazy' height='40px'></img>
                                    <Button variant='outlined' onClick={() => onDelete(integration)}><DeleteIcon></DeleteIcon></Button>
                                </Stack>
                            </Paper>
                        })
                    }
                    <Paper sx={{ padding: "15px" }}>
                        <Stack direction='row' alignItems='center' spacing='15px'>
                            <span>Lägg till</span>
                            <Button variant='outlined' href='/login/tick-tick' LinkComponent='a'><img src={tickTickLogo} loading='lazy' height='40px'></img></Button>
                        </Stack>
                    </Paper>
                </Stack>
            </Stack>
        </Stack>
    </div>;
}