import React, { useEffect, useState } from 'react';
import './CalendarView.css';
import { useNavigate } from 'react-router-dom';
import { addDay, dateToString, getMonth, getWeekDayIndex, isSameDay } from '../dates';
import { Recipe, plannedMeals, imageIdToUrl, PlannedMeal } from '../data';
import { RecipeServingTuple, ShopMeals } from '../components/ShoppingListView';
import { Button, ButtonGroup, Stack, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { AuthStatus, useAuthContext } from '../contexts/AuthProvider';
import { Helmet } from 'react-helmet';



function PlannedRecipe({ recipe }: { recipe: Recipe }) {
    const imgUrl = imageIdToUrl("thumb", recipe?.image_id);
    return <div className='day-button-recipe'>
        <div className='day-button-recipe-name'><div>{recipe.name}</div></div>
        {imgUrl ? <div className='day-button-img'><img src={imgUrl}></img></div> : <></>}
    </div>;
}

interface DayButtonProps {
    date: Date;
    isNextMonth?: boolean;
    recipes?: PlannedMeal[];
    isSelected: boolean;
    onStartSelect: (date: Date) => void;
    onEndSelect: (date: Date, isUp: boolean) => void;
}

function DayButton({ date, isNextMonth, recipes, isSelected, onStartSelect, onEndSelect }: DayButtonProps) {
    const dayNum = date.getDate();
    const isToday = isSameDay(new Date(), date);
    const wrapperClasses = 'day-button-wrapper' + (isSelected ? ' selected' : '');
    const dayTextClasses = 'day-button-day' + (isToday ? ' today' : '');

    const onPointerEnter = (e: React.PointerEvent<HTMLDivElement>) => {
        if (!isPressed(e)) return;

        onEndSelect(date, false);
    };

    const weekDay = getWeekDayIndex(date);
    let borderClass: string = "";
    if (isNextMonth) {
        if (dayNum === 1 && weekDay !== 0) {
            borderClass = " day-next-month-top-left";
        }
        else if (dayNum <= 7) {
            borderClass = " day-next-month-top";
        }
    }

    return <div className={'day-button-month' + (isNextMonth ? " next-month" : "") + borderClass} >
        <div className={wrapperClasses}
            onPointerDown={e => { e.preventDefault(); onStartSelect(date);}}
            onPointerEnter={onPointerEnter}
            onPointerUp={_ => onEndSelect(date, true)}>
            <div className={dayTextClasses}>{dayNum}</div>
            <div className='day-button-recipes'>
                {recipes?.map((r, index) => <PlannedRecipe key={index} recipe={r.recipe}></PlannedRecipe>)}
            </div>
        </div>
    </div>;
}

function isPressed(e: React.PointerEvent<HTMLDivElement>): boolean {
    return (e.buttons & 1) === 1;
}

const enum MonthBorder {
    None = 0,
    Top = 1,
    TopLeft = 2
}

interface Day {
    date: Date;
    isNextMonth: boolean;
    border: MonthBorder;
}

interface Calendar {
    firstMonth: number;
    lastMonth: number;
    days: Day[];
}

function buildCalendar(focus: Date): Date[] {
    const dayInWeek = focus.getDay();
    const mondayThisWeek = addDay(focus, dayInWeek === 0 ? -6 : -(dayInWeek - 1));
    const days = new Array(21).fill(0).map((_, index) => addDay(mondayThisWeek, index - 7));
    return days;
    // const firstMonth = days[0].getMonth();
    // const lastMonth = days[days.length - 1].getMonth();
    // return {
    //     firstMonth,
    //     lastMonth,
    //     days: days.map<Day>(d => {
    //         const isNextMonth = firstMonth !== lastMonth && d.getMonth() === lastMonth;

    //         let border: MonthBorder = MonthBorder.None;
    //         if (isNextMonth) {

    //         }

    //         return {
    //             date: d,
    //             isNextMonth,
    //             border
    //         };
    //     })
    // };
}

function CalendarView() {
    const navigate = useNavigate();
    const auth = useAuthContext();
    const [focusDay, _setFocusDay] = useState<Date>(new Date());
    const [days, setDays] = useState<Date[]>(() => buildCalendar(focusDay));
    const [dateMealMap, setDateMealMap] = useState<Map<Date, PlannedMeal[]>>(new Map());
    const [isSelecting, setIsSelecting] = useState<boolean>(false);
    const [firstDate, setFirstDate] = useState<number | undefined>(undefined);
    const [lastDate, setLastDate] = useState<number | undefined>(undefined);

    const currentHouseholdId = auth.status === AuthStatus.SignedIn ? auth.currentHousehold : undefined;
    const hasSelection = !isSelecting && (firstDate !== undefined) && (lastDate !== undefined);
    const firstMonth = days[0].getMonth();
    const lastMonth = days[days.length - 1].getMonth();

    const setFocusDay = (day: Date) => {
        _setFocusDay(day);
        setDays(buildCalendar(day));
        setFirstDate(undefined);
        setLastDate(undefined);
    };

    useEffect(() => {
        plannedMeals(currentHouseholdId, days[0], days[days.length]).then(plannedMeals => {
            const dateMealMap = new Map<Date, PlannedMeal[]>();
            for (const meal of plannedMeals) {
                const date = new Date(meal.date);
                const foundDate = days.find(d => isSameDay(date, d));
                if (foundDate) {
                    let recipes = dateMealMap.get(foundDate);
                    if (!recipes) {
                        dateMealMap.set(foundDate, recipes = []);
                    }
                    recipes.push(meal);
                }
            }
            setDateMealMap(dateMealMap);
        });
    }, [currentHouseholdId, days]);

    const onStartSelect = (date: Date) => {
        setLastDate(undefined);
        const index = days.indexOf(date);
        setFirstDate(index);
        setLastDate(index);
        setIsSelecting(true);
    };

    const onEndSelect = (date: Date, isUp: boolean) => {
        if (!isSelecting) return;

        setLastDate(days.indexOf(date));
        if (isUp) {
            setIsSelecting(false);
        }
    };

    const onPointerEnter = (e: React.PointerEvent<HTMLDivElement>) => {
        if (isSelecting && !isPressed(e)) {
            setIsSelecting(false);
        }
    };

    const onPlan = () => {
        const range = [firstDate!, lastDate!].sort((a, b) => a - b);
        const start = dateToString(days[range[0]]);
        const end = dateToString(days[range[1]]);

        navigate(`/plan?dates=${encodeURIComponent(`${start}_${end}`)}`);
    };

    const onChangeDateRange = (diffDays: number) => {
        setFocusDay(addDay(focusDay, diffDays));
    };

    const onShowShoppingList = async () => {
        const range = [firstDate!, lastDate!].sort((a, b) => a - b);
        let recipesToShop: ShopMeals = [];
        for (let i = range[0]; i <= range[1]; i++) {
            const date = days[i];
            const plannedMeals = dateMealMap.get(date);
            if (plannedMeals) {
                recipesToShop.push(...plannedMeals.map(r => r.servings ? [r.recipe.id, date.getTime(), r.servings] as RecipeServingTuple : r.recipe.id));
            }
        }

        const meals = encodeURIComponent(JSON.stringify(recipesToShop))
        navigate(`/shopping-list?meals=${meals}`);
    };

    return (
        <div onPointerEnter={onPointerEnter}>
            <Helmet>
                <title>Schema</title>
            </Helmet>
            <Stack direction="row" display="flex" margin="15px 15px 10px 15px" spacing="15px">
                <Typography variant='h4'>{getMonth(days[0])}</Typography>
                <ButtonGroup variant='contained'>
                    <Button disabled={!hasSelection} onClick={onPlan}>Planera</Button>
                    <Button disabled={!hasSelection} onClick={onShowShoppingList}>Inköpslista</Button>
                </ButtonGroup>
                <div style={{ flexGrow: 1 }}></div>
                <Stack direction="row">
                    <Button onClick={() => setFocusDay(new Date())}>Idag</Button>
                    <ButtonGroup variant='outlined'>
                        <Button onClick={() => onChangeDateRange(-14)}><KeyboardArrowUp></KeyboardArrowUp></Button>
                        <Button onClick={() => onChangeDateRange(14)}><KeyboardArrowDown></KeyboardArrowDown></Button>
                    </ButtonGroup>
                </Stack>
            </Stack>
            <div className="calendar-grid">
                {days.map((d, index) => {
                    let isSelected = false;
                    if (firstDate !== undefined && lastDate !== undefined) {
                        if (firstDate < lastDate) {
                            isSelected = firstDate <= index && index <= lastDate;
                        }
                        else {
                            isSelected = lastDate <= index && index <= firstDate;
                        }
                    }

                    return <DayButton
                        key={index}
                        date={d}
                        isNextMonth={firstMonth !== lastMonth && d.getMonth() === lastMonth}
                        recipes={dateMealMap.get(d)}
                        isSelected={isSelected}
                        onStartSelect={onStartSelect}
                        onEndSelect={onEndSelect}></DayButton>
                })}
            </div>
            <div style={{ textAlign: "right" }} className='next-month'>
                <Typography variant='h4'>{firstMonth !== lastMonth ? getMonth(days[days.length - 1]) : <></>}</Typography>
            </div>
        </div>
    );
}

export default CalendarView;
