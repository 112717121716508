import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { PropsWithChildren } from "react";

export interface ConfirmDialogProps {
    open: boolean;
    title: string;
    confirmText?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
}

export default function ConfirmDialog({ open, title, confirmText, onConfirm, onCancel, children }: PropsWithChildren<ConfirmDialogProps>) {
    return <Dialog
        open={open}
        onClose={onCancel}
    >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            {children}
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel}>Avbryt</Button>
            <Button onClick={onConfirm} autoFocus>{confirmText || "OK"}</Button>
        </DialogActions>
    </Dialog>;
}
