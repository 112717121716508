import { Stack, IconButton, TextField } from "@mui/material";
import { PropsWithChildren, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import AcceptOrCancel from "./AcceptOrCancel";

interface EditableTextProps {
    value: string;
    onCommit(newValue: string): Promise<void>;
    onCancel?(): void;
    disabled?: boolean;
    initialIsEditing?: boolean;
}

export default function EditableText(
    { value, onCommit, onCancel, disabled, initialIsEditing, children }: PropsWithChildren<EditableTextProps>
) {
    const [currentValue, setCurrentValue] = useState(value);
    const [isEditing, setIsEditing] = useState<boolean>(initialIsEditing || false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    if (isEditing) {
        const onCancelCallback = () => {
            setIsEditing(false);
            setCurrentValue(value);
            onCancel?.();
        };

        const onAccept = async () => {
            setIsDisabled(true);
            try {
                await onCommit(currentValue);
            }
            finally {
                setIsDisabled(false);
                setIsEditing(false);
                setCurrentValue(currentValue);
            }
        };

        return <Stack direction="row" alignItems="center">
            <TextField
                label="Name"
                variant="outlined"
                value={currentValue}
                disabled={isDisabled}
                onChange={e => setCurrentValue(e.target.value)}
                autoFocus
            />
            <AcceptOrCancel onAccept={onAccept} onCancel={onCancelCallback} isDisabled={isDisabled}></AcceptOrCancel>
            {children}
        </Stack>;
    }

    return <div>
        {value}
        {disabled ?
            <></> :
            <IconButton onClick={() => setIsEditing(true)}>
                <EditIcon fontSize="small"/>
            </IconButton>
        }
    </div>;
}
