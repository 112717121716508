import { Stack, IconButton } from "@mui/material";
import cancelIcon from "../icons/cancel.png";
import acceptIcon from "../icons/accept.png";

interface AcceptOrCancelProps {
    onCancel?: () => void;
    onAccept?: () => void;
    isDisabled?: boolean;
}

export default function AcceptOrCancel({ onCancel, onAccept, isDisabled }: AcceptOrCancelProps) {
    return <Stack direction="row" alignItems="center">
        <IconButton onClick={onCancel} disabled={isDisabled}>
            <img src={cancelIcon} alt='cancel' style={{height: "20px", width: "20px"}}></img>
        </IconButton>
        <IconButton onClick={onAccept} disabled={isDisabled}>
            <img src={acceptIcon} alt='save' style={{height: "20px", width: "20px"}}></img>
        </IconButton>
    </Stack>
}
