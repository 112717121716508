export function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

export interface SortOrderType {
    sort_order: number;
}

export interface ReorderSortOrderResult {
    index: number;
    sort_order: number;
}

export function reorderSortOrder<T extends SortOrderType>(
    list: T[],
    startIndex: number,
    endIndex: number
): ReorderSortOrderResult[] {
    if (startIndex === endIndex) return [];

    if (endIndex === 0) {
        return [{ index: startIndex, sort_order: list[endIndex].sort_order - 10 }];
    }

    if (endIndex === (list.length - 1)) {
        return [{ index: startIndex, sort_order: list[endIndex].sort_order + 10 }];
    }

    const before = list[endIndex - 1].sort_order;
    const after = list[endIndex].sort_order;
    if (after - before < 2) {
        // Reorder everything
        return reorder(list, startIndex, endIndex).map((t, index) => ({ index, sort_order: index * 10 }));
    }

    return [{ index: startIndex, sort_order: before + Math.round((after - before) / 2) }];
}

export function move<T>(
    source: T[], destination: T[], fromIndex: number, toIndex: number
): [source: T[], destination: T[]] {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(fromIndex, 1);

    destClone.splice(toIndex, 0, removed);

    return [sourceClone, destClone];
}

export function replace<T>(source: T[], index: number, newItem: T): T[] {
    return [
        ...source.slice(0, index),
        newItem,
        ...source.slice(index + 1)
    ];
}

export function remove<T>(
    source: T[], index: number
): T[] {
    const sourceClone = Array.from(source);
    sourceClone.splice(index, 1);
    return sourceClone;
}

export function findIndex<T>(list: T[], startIndex: number, predicate: (value: T) => boolean): number {
    const length = list.length;
    for (let index = startIndex; index < length; ++index) {
        if (predicate(list[index])) {
            return index;
        }
    }

    return -1;
}

export function find<T>(list: T[], startIndex: number, predicate: (value: T) => boolean): T | undefined {
    const index = findIndex(list, startIndex, predicate);
    return index === -1 ? undefined : list[index];
}

export function findLast<T>(list: T[], startIndex: number, predicate: (value: T) => boolean): T | undefined {
    for (let index = startIndex; index >= 0; --index) {
        const value = list[index];
        if (predicate(value)) {
            return value;
        }
    }

    return undefined;
}
