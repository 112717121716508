import { Dialog, DialogTitle, Paper, Stack, Button, CircularProgress, DialogContent, DialogActions, Typography, Divider } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { addToTickTickList, categorizeIngredient, getUserTickTickList, getUserTickTickLists, NewTickTickTask, TickTick, TickTickList, TickTickTask, UpdateTickTickTask } from "../data";
import { IngredientSource, ShoppingListCategory, ShoppingListIngredient, sumSources, useShoppingList } from "../contexts/ShoppingListContext";
import { buildIngredientString, buildShoppingItem, buildShoppingItemInfo, displayQuantityValue, ParsedIngredient, parseIngredientDays, parseIngredientString } from "../utils/shoppingLists";
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from "@hello-pangea/dnd";
import { findIndex, findLast, reorder } from "../utils/list";
import { UniqueId } from "../utils/unique";
import { capitalizeFirst } from "../textFormattings";
import PendingButton from "./PendingButton";

interface IngredientCategory {
    type: 'category';
    id?: number;
    name?: string | null;
}

interface IngredientEntry {
    type: 'entry';
    uniqueId: string;
    ingredient?: ShoppingListIngredient;
    task?: { t: TickTickTask; i?: ParsedIngredient } | null;
}

type Entry = IngredientCategory | IngredientEntry;

function isCategory(entry: Entry): entry is IngredientCategory {
    return entry.type === 'category';
}

function isIngredient(entry: Entry): entry is IngredientEntry {
    return entry.type === 'entry';
}

function Category({ name, index }: { name: string; index: number; }) {
    return <Draggable draggableId={name} index={index} isDragDisabled={true}>
        {(provided, snapshot) => (
            <span
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                {name}
            </span>
        )}
    </Draggable>;
}

interface IngredientProps {
    entry: IngredientEntry;
    index: number;
}

function Ingredient({ entry: e, index }: IngredientProps) {
    let innerContent: ReactElement;
    const isNew = e.ingredient && !e.task;
    let isModified = false;
    if (e.ingredient) {
        const info = buildShoppingItemInfo(e.ingredient);
        let title: string | ReactElement[];
        if (e.task?.i) {
            isModified = true;
            const prettyName = capitalizeFirst(e.ingredient.name);
            const oldQuantity = e.task.i.quantity ? displayQuantityValue(e.task.i.quantity) : undefined;
            const newQuantity = e.ingredient.quantity ? displayQuantityValue(e.ingredient.quantity) : undefined;
            title = [
                <span key='1'>{prettyName}, </span>,
                <s key='2'>{oldQuantity} {e.task?.i?.unit}</s>,
                <span key='3'> {newQuantity} {e.ingredient.unit}</span>
            ];
        }
        else {
            title = buildIngredientString(e.ingredient.name, e.ingredient.quantity, e.ingredient.unit);
        }
        
        let desc: string | ReactElement[];
        const descText = info.distribution || info.date?.weekDay;
        if (e.task && e.task.t.desc && e.task.t.desc !== descText) {
            desc = [
                <span key='1'>{descText}</span>,
                <br key='2'></br>,
                <s key='3'>{e.task.t.desc}</s>,
            ];
        }
        else {
            desc = descText || "";
        }
        innerContent = <>
            <Typography>{title}</Typography>
            <Typography variant="subtitle2">{desc}</Typography>
        </>;
    }
    else if (e.task) {
        const t = e.task.t;
        innerContent = <>
            <Typography>{t.title}</Typography>
            <Typography variant="subtitle2">{t.desc || t.content}</Typography>
        </>;
    }
    else {
        innerContent = <></>;
    }

    return <Draggable draggableId={e.uniqueId} index={index}>
        {(provided, snapshot) => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                <Paper
                    style={{ backgroundColor: isNew ? '#ccffc1' : (isModified ? '#fff395' : 'white' ), marginLeft: '15px' }}
                >
                    <Stack
                        paddingLeft='15px'
                        paddingRight='15px'
                        paddingTop='5px'
                        paddingBottom='5px'
                        direction='row'
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        {innerContent}
                    </Stack>
                </Paper>
            </div>
        )}
    </Draggable>;
}

interface IngredientsToBuyProps {
    entries: Entry[];
}

function IngredientsToBuy({ entries }: IngredientsToBuyProps) {
    return <Droppable droppableId="list">
        {(provided, snapshot) => (
            <Stack
                {...provided.droppableProps}
                ref={provided.innerRef}
                direction='column'
                spacing='15px'
                paddingTop='15px'
            >
                {entries.map((e, index) => e.type === 'category' ?
                    <Category key={e.name || 'Ingen kategori'} name={e.name || 'Ingen kategori'} index={index}></Category> :
                    <Ingredient key={e.uniqueId} entry={e} index={index}></Ingredient>)}
                {provided.placeholder}
            </Stack>
        )}
    </Droppable>;
}


export interface TickTickDialogProps {
    open: boolean
    onClose?: () => void;
    shoppingListToAdd: ShoppingListCategory[];
}

interface Selected {
    tickTick: TickTick;
    list: TickTickList;
}

export default function TickTickDialog({ open, onClose, shoppingListToAdd }: TickTickDialogProps) {
    const { ingredientToCategory } = useShoppingList();
    const [tickTick, setTickTick] = useState<TickTick[] | undefined>();
    const [selectedList, setSelectedList] = useState<Selected | undefined>();
    const [taskList, setTaskList] = useState<Entry[] | undefined>();
    const [isAdding, setIsAdding] = useState<boolean>(false);

    useEffect(() => {
        getUserTickTickLists().then(setTickTick);
    }, [setTickTick]);
    
    const onSelectList = (tickTick: TickTick, list: TickTickList) => {
        setTaskList(undefined);
        setSelectedList({ tickTick, list });

        getUserTickTickList(tickTick.id, list.id).then(tasks => {
            const idGen = new UniqueId();
            tasks.sort((a, b) => a.sortOrder - b.sortOrder);

            let entries: Entry[] = [];
            for (const c of shoppingListToAdd) {
                entries.push({ type: 'category', id: c.id, name: c.name });
                for (const ingredient of c.items) {
                    entries.push({
                        type: 'entry',
                        uniqueId: idGen.next(),
                        ingredient: { ...ingredient, sources:  [...ingredient.sources ] }
                    });
                }
            }

            for (const task of tasks) {
                const ingredient = parseIngredientString(task.title);
                const days = parseIngredientDays(task.desc);
                if (ingredient && ingredient.quantity && ingredient.quantity.type === "number") {
                    let handled = false;
                    for (const entry of entries) {
                        if (entry.type !== "entry" || !entry.ingredient) continue;
                        
                        if (entry.ingredient.name === ingredient.name &&
                            entry.ingredient.quantity &&
                            entry.ingredient.unit === ingredient.unit)
                        {
                            const quantity = entry.ingredient.quantity;
                            if (quantity.type === "text" || quantity.type === "range") {
                                continue;
                            }

                            let tickTickSources: IngredientSource[];
                            let tickTickSource: IngredientSource = {
                                name: "TickTick",
                                ingredient: { ...ingredient, uniqueId: idGen.next(), scale_outcome: 'scaled' },
                            };
                            if (days.length === 1) {
                                tickTickSource.day = days[0].day;
                                tickTickSources = [ tickTickSource ];
                            }
                            else if (days.length >= 2) {
                                tickTickSources = days.map(day => {
                                    const ingredient = { ...tickTickSource.ingredient };
                                    if (day.quantity) {
                                        ingredient.quantity = day.quantity;
                                    }
                                    return {
                                        name: tickTickSource.name,
                                        ingredient,
                                        day: day.day,
                                    };
                                });
                            }
                            else {
                                tickTickSources = [ tickTickSource ];
                            }

                            const sources = entry.ingredient.sources.concat(tickTickSources);
                            entry.ingredient.sources = sources;
                            entry.ingredient.quantity = sumSources(sources);
                            entry.task = { t: task, i: ingredient };
                            handled = true;
                        }
                    }
                    if (handled) continue;
                }
                const entry: IngredientEntry = { type: 'entry', uniqueId: idGen.next(), task: { t: task } };

                const category = ingredientToCategory.get(ingredient?.name || task.title.toLowerCase());
                if (category) {
                    const categoryIndex = entries.findIndex(e => e.type === 'category' && e.id === category.id);
                    if (categoryIndex !== -1) {
                        const nextCategoryIndex = findIndex(entries, categoryIndex + 1, isCategory);
                        if (nextCategoryIndex !== -1) {
                            entries.splice(nextCategoryIndex, 0, entry);
                            continue;
                        }
                    }
                }
                entries.push(entry);
            }

            setTaskList(entries);
        });
    };

    const onDragEnd: OnDragEndResponder = (result) => {
        const { source, destination } = result;
        if (!destination || !taskList) {
            return;
        }

        const lastCategory = findLast(taskList, source.index, isCategory);
        const newCategory = findLast(taskList, destination.index, isCategory) as IngredientCategory;
        if (lastCategory !== newCategory && typeof newCategory.id === "number") {
            const entry = taskList[source.index];
            if (entry.type === "entry" && entry.ingredient) {
                categorizeIngredient(newCategory.id, entry.ingredient.name);
            }
        }
        const newTaskList = reorder(taskList, source.index, destination.index);
        setTaskList(newTaskList);
    };

    const onBack = () => {
        if (selectedList) {
            setSelectedList(undefined);
            return;
        }

        onClose?.();
    };

    const onAdd = () => {
        if (!taskList || !selectedList || isAdding) return;

        const newTasks: (NewTickTickTask | UpdateTickTickTask)[] = [];
        const entries: IngredientEntry[] = [];
        let firstDefinedSortOrder: number = (taskList.find(t => t.type === 'entry' && t.task) as IngredientEntry)?.task!.t.sortOrder;
        let prevSortOrder: number = firstDefinedSortOrder - 10_000_000;
        for (let index = 0; index < taskList.length; ++index) {
            const entry = taskList[index];
            if (entry.type !== 'entry') continue;

            const i = entry.ingredient;
            if (i) {
                const { item, extra, date } = buildShoppingItem(i);
                const title = item;
                const desc = extra || date?.weekDay;
                if (entry.task) {
                    if (entry.task.t.sortOrder < prevSortOrder) {
                        prevSortOrder += 10_000;
                    }
                    else {
                        prevSortOrder = entry.task.t.sortOrder;
                    }
                    if (entry.task.t.title !== title || entry.task.t.desc !== desc) {
                        entries.push(entry);
                        newTasks.push({
                            id: entry.task.t.id,
                            title,
                            desc,
                            content: desc,
                            sortOrder: prevSortOrder !== entry.task.t.sortOrder ? prevSortOrder : undefined
                        });
                    }
                    else if (prevSortOrder !== entry.task.t.sortOrder) {
                        entries.push(entry);
                        newTasks.push({
                            id: entry.task.t.id,
                            sortOrder: prevSortOrder
                        });
                    }
                }
                else {
                    prevSortOrder += 10_000;
                    entries.push(entry);
                    newTasks.push({ title, desc, content: desc, sortOrder: prevSortOrder });
                }
            }
            else if (entry.task) {
                if (entry.task.t.sortOrder < prevSortOrder) {
                    prevSortOrder += 10_000;
                    entries.push(entry);
                    newTasks.push({
                        id: entry.task.t.id,
                        sortOrder: prevSortOrder
                    });
                }
                else {
                    prevSortOrder = entry.task.t.sortOrder;
                }
            }
        }

        setIsAdding(true);
        addToTickTickList(selectedList.tickTick.id, selectedList.list.id, newTasks).then(tasks => {
            tasks.forEach((task, index) => {
                if (task) {
                    entries[index].task = { t: task };
                }
            });
            setTaskList([...taskList]);
        })
        .finally(() => {
            setIsAdding(false);
        });
    };

    let content: ReactElement | ReactElement[];
    if (selectedList) {
        
        let innerContent: ReactElement | ReactElement[];
        if (taskList) {
            innerContent = <DragDropContext onDragEnd={onDragEnd}>
                <IngredientsToBuy entries={taskList}></IngredientsToBuy>
            </DragDropContext>;
        }
        else {
            innerContent = <CircularProgress />;
        }

        content = <Stack direction='column'>
            <Typography variant="h6">{selectedList.list.name}</Typography>
            <Divider></Divider>
            {innerContent}
        </Stack>
    }
    else if (tickTick) {
        content = tickTick.map(t => {
            return <Paper key={"" + t.id}>
                <Stack direction='column' spacing='15px' padding='15px'>
                    <div>{t.name}</div>
                    {t.lists.map(l => {
                        return <Button key={l.id} style={{ color: l.color || "black" }} variant='outlined' onClick={() => onSelectList(t, l)}>{l.name}</Button>;
                    })}
                </Stack>
            </Paper>;
        })
    }
    else {
        content = <CircularProgress />;
    }

    return <Dialog open={open} onClose={onClose} fullWidth={true}>
        <DialogTitle>Lägg till i TickTick</DialogTitle>
        <DialogContent>
            {content}
        </DialogContent>
        <DialogActions>
            <Button onClick={onBack}>{selectedList ? "Back" : "Close"}</Button>
            <PendingButton
                onClick={onAdd}
                disabled={!(selectedList && taskList)}
                pending={isAdding}
                variant="contained"
            >Lägg till</PendingButton>
        </DialogActions>
    </Dialog>;
}

