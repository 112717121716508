import { IngredientSource, ShoppingListCategory, ShoppingListIngredient, sumSources } from "../contexts/ShoppingListContext";
import { CooklangNumber, QuantityValue } from "../data";
import { getShortWeekDay, isSameDay, parseDay } from "../dates";
import { capitalizeFirst } from "../textFormattings";

function round(num: number): string {
    const diff = num - Math.trunc(num);
    if (diff < 0.01) {
        return "" + Math.trunc(num)
    }

    return num.toFixed(2);
}

function displayCooklangNumber(v: CooklangNumber, fractionAsNumber?: boolean): string {
    switch (v.type) {
        case "regular":
            return round(v.value);

        case "fraction":
            const { whole, num, den } = v.value;
            if (fractionAsNumber) {
                const value = whole + num / den;
                return round(value);
            }

            if (whole) {
                return `${whole} ${num}/${den}`;
            }

            return `${num}/${den}`;
    }
}

export function displayQuantityValue(v: QuantityValue, fractionAsNumber?: boolean): string {
    switch (v.type) {
        case "number":
            return displayCooklangNumber(v.value, fractionAsNumber);

        case "range":
            const start = displayCooklangNumber(v.start, fractionAsNumber);
            const end = displayCooklangNumber(v.end, fractionAsNumber);
            return `${start} - ${end}`;

        case "text":
            return v.value;
    }
}

export function buildIngredientString(name: string, quantity?: QuantityValue, unit?: string): string {
    const prettyName = capitalizeFirst(name);
    if (quantity) {
        return `${prettyName}, ${displayQuantityValue(quantity)}${unit ? (" " + unit) : ""}`;
    }
    else {
        return prettyName;
    }
}

export interface ParsedIngredient {
    name: string;
    quantity?: QuantityValue;
    unit?: string;
}

export interface ParsedIngredientDay {
    day: Date;
    quantity?: QuantityValue;
}

export function parseIngredientString(text: string): ParsedIngredient | undefined {
    const parts = text.split(",");
    if (parts.length === 1) {
        return { name: text.toLowerCase() };
    }
    else if (parts.length === 2) {
        const name = parts[0].trim().toLowerCase();
        let quantity = parts[1].trim();
        const firstSpace = quantity.indexOf(' ');
        if (firstSpace !== -1) {
            const value = parseFloat(quantity.slice(0, firstSpace));
            if (Number.isFinite(value)) {
                let unit = quantity.slice(firstSpace + 1).trim();
                return {
                    name,
                    quantity: { type: 'number', value: { type: 'regular', value } },
                    unit: unit ? unit : undefined
                };
            }
        }

        return { name, quantity: { type: 'text', value: quantity } };
    }
}

export function parseIngredientDays(text: string | undefined): ParsedIngredientDay[] {
    const days: ParsedIngredientDay[] = [];
    if (!text) return days;

    for (const part of text.split(",").map(t => t.trim())) {
        const dayParts = part.split(": ");
        if (dayParts.length === 1) {
            const day = parseDay(dayParts[0]);
            if (day) days.push({ day });
        }
        else if (dayParts.length === 2) {
            const day = parseDay(dayParts[0]);
            const value = parseFloat(dayParts[1].trim());
            if (day && Number.isFinite(value)) {
                days.push({ day, quantity: { type: 'number', value: { type: 'regular', value } } });
            }
        }
    }

    return days;
}

export interface ItemDate {
    weekDay: string;
    date: Date;
}

export interface Info {
    distribution?: string;
    date?: ItemDate;
}

export interface Item {
    date?: ItemDate;
    item: string;
    extra?: string;
}

export interface Category {
    uniqueId: string;
    id?: number;
    name: string | null;
    items: Item[];
}

export function buildShoppingItemInfo(i: ShoppingListIngredient): Info {
    const dayQuantity = new Map<number, IngredientSource[]>();
    for (const s of i.sources) {
        if (s.day) {
            const time = s.day.getTime();
            let sources = dayQuantity.get(time);
            if (!sources) {
                dayQuantity.set(time, sources = []);
            }
            sources.push(s);
        }
    }

    if (dayQuantity.size <= 1) {
        const timestamp = dayQuantity.keys().next().value;
        if (timestamp === undefined) {
            return {};
        }
        else {
            const date = new Date(timestamp);
            return {
                date: {
                    weekDay: getShortWeekDay(date),
                    date,
                }
            };
        }
    }
    else {
        const entries = Array.from(dayQuantity.entries()).sort((a, b) => a[0] - b[0]);
        const firstDate = new Date(entries[0][0]);
        const distribution = entries.reduce((text, [dateTime, sources]) => {
            const date = new Date(dateTime);
            const quantity = sumSources(sources);
            if (quantity) {
                if (text.length) {
                    text += ", ";
                }
                text += `${getShortWeekDay(date)}: ${displayQuantityValue(quantity)}`
            }

            return text;
        }, "");

        return {
            date: {
                weekDay: getShortWeekDay(firstDate),
                date: firstDate,
            },
            distribution,
        };
    }
}

export function buildShoppingItem(i: ShoppingListIngredient): Item {
    const info = buildShoppingItemInfo(i);
    const ingredientString = buildIngredientString(i.name, i.quantity, i.unit);

    return {
        item: ingredientString,
        date: info.date,
        extra: info.distribution
    };
}

export function buildShoppingList(list: ShoppingListCategory[]): Category[] {
    let categories: Category[] = [];
    for (const c of list) {
        let items: Item[] = [];
        for (const i of c.items) {
            items.push(buildShoppingItem(i));
        }

        categories.push({ uniqueId: c.uniqueId, name: c.name, id: c.id, items });
    }

    return categories;
}

export function findDuplicateWeekDays(items: Item[]): Set<string> {
    const duplicateWeekDays = new Set<string>();
    const usedWeekDays = new Map<string, Date>();
    for (const item of items) {
        if (!item.date) continue;

        const { weekDay, date } = item.date;

        let time = usedWeekDays.get(weekDay);
        if (time === undefined) {
            usedWeekDays.set(weekDay, date);
        }
        else if (!isSameDay(time, date)) {
            duplicateWeekDays.add(weekDay);
        }
    }

    return duplicateWeekDays;
}

export function buildShoppingListString(list: ShoppingListCategory[]): string {
    const items = buildShoppingList(list)
        .reduce((items, c) => items.concat(c.items), [] as Item[]);
    const duplicateWeekDays = findDuplicateWeekDays(items);

    return items.map(item => {
        const itemText = item.extra ? `${item.item} (${item.extra})` : item.item;
        if (item.date) {
            const { weekDay, date } = item.date;
            if (duplicateWeekDays.has(weekDay)) {
                return `${weekDay} (${date.getDate()}/${date.getMonth() + 1}): ${itemText}`;
            }
            else {
                return `${weekDay}: ${itemText}`;
            }
        }
        else {
            return itemText;
        }
    }).join("\n");
}
